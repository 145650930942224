import React, { useContext, useEffect, useState, useRef } from "react";
import { Container, Col, Row } from "react-bootstrap";
import News3 from "../News3";
import { AppContext } from "../../AppContext";
import moment from "moment";
import axios from "axios";
import { useParams } from "react-router-dom";
import Breadcrumbs from "./Breadcrumbs";
import ScrollToTopOnMount from "../ScrollToTopOnMount";
import GoToTop from "../GoToTop";
import Sidebar from "../about/Sidebar";

function Post() {
  const { posts } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [authorName, setAuthorName] = useState("");
  const [categories, setCategories] = useState("");
  const [title, setTitle] = useState("");
  const [modified, setModified] = useState("");
  const [content, setContent] = useState("");

  const matchingPost = useRef(null);
  const { id } = useParams();

  useEffect(() => {
    setLoading(true);

    async function loadData() {
      if (posts) {
        if (matchingPost.current) {
          let author;
          const categoriesArray = [];

          const authorId = matchingPost.current.author;
          const authorUrl = `https://cmfe.org/blog/wp-json/wp/v2/users/${authorId}`;
          const getAuthor = axios(authorUrl);

          const getCategoriesArray = [];
          const categoryIds = matchingPost.current.categories;
          categoryIds.forEach((categoryId) => {
            const categoriesUrl = `https://cmfe.org/blog/wp-json/wp/v2/categories/${categoryId}`;
            const getCategories = axios(categoriesUrl);
            getCategoriesArray.push(getCategories);
          });

          axios
            .all([getAuthor, ...getCategoriesArray])
            .then(
              axios.spread((...responses) => {
                const authorResponse = responses[0];
                author = authorResponse.data;

                const categoriesCount = getCategoriesArray.length + 1;
                for (let index = 1; index < categoriesCount; index += 1) {
                  const categoriesResponse = responses[index];
                  categoriesArray.push(categoriesResponse.data.name);
                }

                setAuthorName(author.name);
                if (categoriesArray && categoriesArray.length > 0) {
                  setCategories(categoriesArray.join(", "));
                }
              })
            )
            .catch((errors) => {
              console.log(errors);
            });
          setLoading(false);
        }
      }
    }

    if (posts && id) {
      const match = posts.filter((aPost) => `${aPost.id}` === id);
      if (match.length > 0 && match[0]) {
        matchingPost.current = match[0];
        if (match[0].title) {
          setTitle(match[0].title.rendered);
        }
        setModified(match[0].modified);
        if (match[0].content) {
          setContent(match[0].content.rendered);
        }
        loadData();
      }
    }
  }, [posts, id, matchingPost]);

  if ((!posts || !matchingPost.current) && !loading) return null;

  if (loading)
    return (
      <Container className="main align-content-start">
        <ScrollToTopOnMount />
        {/* <Breadcrumbs activePage="Events" /> */}
        <Container className="page article-content posts">
          <div className="d-flex justify-content-center mt-3 mb-3">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </Container>
      </Container>
    );

  return (
    <Container className="main align-content-start latest">
      <Container className="article-content posts">
        <ScrollToTopOnMount />
        <Breadcrumbs activePage={title} />
        <Container>
          <Row>
            <Col md={9}>
              <h1 dangerouslySetInnerHTML={{ __html: title }} />
              <div className="d-flex">
                <article
                  style={{ width: "100%", overflow: "auto" }}
                  className="mb-5"
                >
                  <div className="post-meta d-flex">
                    {authorName ? (
                      <small className="me-2 author text-secondary">
                        <strong>Author:</strong> {authorName}
                      </small>
                    ) : null}
                    {modified ? (
                      <small className="me-2 date text-secondary">
                        <strong>Last Updated:</strong>{" "}
                        {moment(modified).format(
                          "dddd, MMMM Do YYYY, h:mm:ss a"
                        )}
                      </small>
                    ) : null}
                    {categories ? (
                      <small className="tag text-secondary">
                        <strong>Tag:</strong> {categories}
                      </small>
                    ) : null}
                  </div>
                  <div
                    className="post-content mt-3"
                    dangerouslySetInnerHTML={{ __html: content }}
                  />
                </article>
              </div>
            </Col>
            <Col md={3}>
              <Sidebar />
            </Col>
          </Row>
        </Container>
        <GoToTop />
      </Container>
      <Container id="related-posts">
        <div className="row justify-content-center p-5">
          <h4 className="text-uppercase mb-5">Check Out These Other Posts</h4>
          <Container>
            <News3 mainPostId={id} />
          </Container>
        </div>
      </Container>
    </Container>
  );
}

export default Post;
