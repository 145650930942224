import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Breadcrumbs from './Breadcrumbs';
import Sidebar from './Sidebar';
import ExternalLink from '../shared/ExternalLink';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import GoToTop from '../GoToTop';

function Permits() {
    return (
        <>
            <Container id="permits" className="main">
                <ScrollToTopOnMount />
                <Container className="page">
                    <Breadcrumbs activePage="Permits" />
                    <Container>
                        <Row>
                            <Col md={9}>
                                <h1>Permits</h1>
                                <section className="mt-3 mb-3" style={{ width: 'inherit', wordBreak: 'break-word'}}>
                                    <p>Central Mason Fire and EMS Fire Marshal’s Office conducts plan and permit review for Fire and Life Safety in the City of Shelton. Please allow 2-4 weeks for plan review depending on the project size.</p>
                                    <p>The Fire Marshal’s Office currently conducts plan and permit review under the following Code Books as adopted by Washington State (WAC 51-54A-003) and the City of Shelton (Municipal Code Chapter 18.06):</p>
                                    <ul>
                                        <li>International Fire Code 2015</li>
                                        <li>International Building Code 2015</li>
                                        <li>International Mechanical Code 2015</li>
                                    </ul>
                                    <p>Permit Applications can be found at the City of Shelton Civic Center or <ExternalLink href="https://www.sheltonwa.gov/departments/community_and_economic_development/building_permits.php" />.</p>
                                </section>
                            </Col>
                            <Col md={3}>
                                <Sidebar />

                            </Col>
                        </Row>
                    </Container>
                </Container>
                <GoToTop />
            </Container>
        </>
    );
}

export default Permits;
