import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Breadcrumbs from './Breadcrumbs';
import Sidebar from './Sidebar';
import Phone from '../shared/Phone';
import ExternalLink from '../shared/ExternalLink';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import GoToTop from '../GoToTop';

function KnoxBoxes() {

    return (
        <>
            <Container id="knoxboxes" className="main">
                <ScrollToTopOnMount />
                <Container className="page">
                    <Breadcrumbs activePage="Knox Boxes" />
                    <Container>
                        <Row>
                            <Col md={9}>
                                <h1>Knox Boxes</h1>
                                <section className="mt-3 mb-3">
                                    <p><strong>Knox Boxes</strong> are steel boxes that are bolted to the side of the building. Knox Boxes helps provide emergency responders with quick access to the building in case of an emergency.</p>
                                    <p>When you go to buy a Knox box the site will ask you for your department type “<i>Central Mason Fire & EMS</i>”. Once you place your order, it will send a request to our Fire Marshal’s Office for approval.</p>
                                    <p>Your Knox box will come in the unlocked position. Once you have the Knox box mounted and have your keys ready, call the Fire Marshal’s Office at <Phone number="360-462-5020" /> to schedule us to come out and lock the Knox Box.</p>
                                    {/* <Alert className="text-center p-2 m-3" variant="warning">If you need help installing or getting smoke detectors call the Fire Marshal’s Office at <Phone number="360-462-5020" />.</Alert> */}
                                </section>
                                <hr className="m-4" />
                                <section className="mt-3 mb-3">
                                    <h4 className="mb-3">Our Promise to You</h4>
                                    <p>We take the trust you give us when you give us access to your house, property or business very seriously. To make sure there is no misuse of Knox Keys and to prevent keys from getting stolen, we have installed key secures in all our apparatus. Each employee has their own passcode to get the keys, and the time is logged that the key was removed and returned.</p>
                                </section>
                                <hr className="m-4" />
                                <section className="mt-3 mb-3">
                                    <h4 className="mb-3">Residential</h4>
                                    <p>To find more information or to order, go to <ExternalLink href="https://www.knoxbox.com/Products/Residential-KnoxBoxes" />.</p>
                                </section>
                                <hr className="m-4" />
                                <section className="mt-3 mb-3">
                                    <h4 className="mb-3">Gates</h4>
                                    <p>There are a couple options you have if you have a locked gate that you want emergency responders to have access to.</p>
                                    <ul>
                                        <li>If you have a manual gate you can use a Knox padlock, which uses our Knox keys to unlock it.</li>
                                        <li>If it is an electronic gate you there are Gate and Key Switches that be wired right to your gate, so that when we use our Knox key to open the gate.</li>
                                    </ul>
                                    <p>To find more information or to order, go to <ExternalLink href="https://www.knoxbox.com/Products/Gate-Key-Switches-and-Padlocks" />.</p>
                                </section>
                                <hr className="m-4" />
                                <section className="mt-3 mb-3">
                                    <h4 className="mb-3">Commercial</h4>
                                    <p>In Central Mason Fire and EMS and the City of Shelton, we require all commercial businesses with automatic fire alarms to have a Knox box per the International Fire Code Section 506. This will make sure in case of a fire alarm outside of business hours that we can make quick non-destructive access to the building.</p>
                                    <p>To find more information or to order, go to <ExternalLink href="https://www.knoxbox.com/Products/Commercial-KnoxBoxes" />.</p>
                                </section>
                            </Col>
                            <Col md={3}>
                                <Sidebar />

                            </Col>
                        </Row>
                    </Container>
                </Container>
                <GoToTop />
            </Container>
        </>
    );
}

export default KnoxBoxes;
