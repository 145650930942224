import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext";
import { Container, Row, Col } from "react-bootstrap";
import Breadcrumbs from "./Breadcrumbs";
import Sidebar from "./Sidebar";
import ScrollToTopOnMount from "../ScrollToTopOnMount";
import GoToTop from "../GoToTop";

function OurStations() {
  const { pages, pagesAreLoading } = useContext(AppContext);
  const [pageData, setPageData] = useState({});
  useEffect(() => {
    const getPage = () => {
      if (pages) {
        let thisPage = {};
        pages.forEach((page, index) => {
          if (page && page.link.indexOf("station-locations") > -1) {
            thisPage = page;
          }
        });
        setPageData(thisPage);
      }
    };

    if (pages) {
      getPage();
    }
  }, [pages]);

  if (pagesAreLoading) {
    return (
      <Container>
        <div className="d-flex justify-content-center mt-5 mb-3">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </Container>
    );
  }

  if (!pages) return null;
  return (
    <Container id="our-stations" className="main">
      <ScrollToTopOnMount />
      <Container className="page">
        <Breadcrumbs activePage="Our Stations" />
        <Container>
          <Row>
            <Col
              className="left"
              md={{ span: 9, order: 1 }}
              sm={{ span: 12, order: 2 }}
            >
              <h1 dangerouslySetInnerHTML={{__html: pageData?.title?.rendered}} />
                {pageData?.content?.rendered ? (
                  <div
                    className="mt-3"
                    dangerouslySetInnerHTML={{
                      __html: pageData.content.rendered,
                    }}
                  />
                ) : null}
            </Col>
            <Col
              className="right"
              md={{ span: 3, order: 2 }}
              sm={{ span: 12, order: 1 }}
            >
              <Sidebar />
            </Col>
          </Row>
        </Container>
      </Container>
      <GoToTop />
    </Container>
  );
}

export default OurStations;
