import React from 'react';
import { Container } from 'react-bootstrap';

function MissionMotto () {
    return (
        <>
        <Container className="border-shadow">
            <h2>Our Mission</h2>
            <p>To make a positive difference in the place where people live, work, and play by providing the highest level of fire and emergency services.</p>
        </Container>
        <Container className="border-shadow mt-3">
            <h2>Our Motto</h2>
            <p>Building a fire safe community.</p>
        </Container>
        </>
    );
}

export default MissionMotto;
