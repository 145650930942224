import React from "react";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";

function Sidebar() {
  return (
    <>
      <div id="sidebar" className="pt-4 pb-4 ps-3 pe-3 mb-3 bg-light shadow-sm">
        <Nav defaultActiveKey="/" className="flex-column">
          <Nav.Link
            as={NavLink}
            end
            to="/commissioners"
          >
            Commissioners
          </Nav.Link>
          <Nav.Link
            as={NavLink}
            end
            to="/commissioners/minutes"
          >
            Agendas & Minutes
          </Nav.Link>
          {/* <Nav.Link as={NavLink} end to="/commissioners/events">Calendar</Nav.Link> */}
          {/* <Nav.Link as={NavLink} end to="/commissioners/zoom-help">Zoom Help</Nav.Link> */}
        </Nav>
      </div>
    </>
  );
}

export default Sidebar;
