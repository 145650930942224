import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Breadcrumbs from './Breadcrumbs';
// import { Link } from 'react-router-dom';
import Sidebar from './Sidebar';
import Phone from '../shared/Phone';
import GoToTop from '../GoToTop';
import ScrollToTopOnMount from '../ScrollToTopOnMount';

function FireMarshal() {
    return (
        <>
            <Container id="fire-marshal" className="main">
                <ScrollToTopOnMount />
                <Container className="page">
                    <Breadcrumbs activePage="Fire Marshal's Office" />
                    <Container>
                        <Row>
                            <Col md={9}>
                                <h1>Fire Marshal's Office</h1>
                                <section className="mt-3 mb-3">
                                    <h4 className="text-uppercase mb-3">Services</h4>
                                    <p>The Fire Marshal's Office ensures that all new and existing structures are in compliance with the International Fire Code as adopted by the State of Washington. This mission is accomplished through technical plan review of new construction projects, Fire/Safety inspection services, including, new and existing occupancies, and investigation of fires to determine the origin and cause.</p>
                                    <p>The Fire Marshal's Office strives to be a partner, rather than a perceived barrier, in Central Mason’s economic development. Balancing the property rights of developers and building owners with the public's right to safe buildings, the Fire Marshal's Office succeeds as a public service entity.</p>
                                </section>
                                <hr className="m-4" />
                                <section className="mt-3 mb-3">
                                    <h4 className="text-uppercase mb-3">Plan Review</h4>
                                    <p>The Fire Marshal reviews construction drawings, site plans, fire sprinkler, and fire alarm plans to ensure compliance with national, state, and local fire codes. On-site inspections of new construction projects proceed throughout all stages of the construction process. A certificate of occupancy is granted when the structure has complied with all Fire Department requirements.</p>
                                </section>
                                <hr className="m-4" />
                                <section className="mt-3 mb-3">
                                    <h4 className="text-uppercase mb-3">Fire/Safety Inspections</h4>
                                    <p>All commercial occupancies and public buildings are inspected to ensure compliance with the International Fire Code. In Central Mason, the public is provided with safe buildings in which to work and shop. Buildings are inspected based on their occupancy classification, the inspection schedule is posted on the website.</p>
                                </section>
                                <hr className="m-4" />
                                <section className="mt-3 mb-3">
                                    <h4 className="text-uppercase mb-3">Fire Investigation</h4>
                                    <p>The Fire Marshal is tasked with determining the origin and cause of all fires that occur within the City of Shelton as well as Mason County. The information gathered from fire investigations is used to identify fire problems and trends, so they can be mitigated in the future.</p>
                                    <p>The Fire Marshal works with the Shelton Police Department and Mason County Sheriff’s Office to investigate fires that are suspicious or determined to have been intentionally set. This Police/Fire arson team is dedicated to solving arson cases and prosecuting those responsible.</p>
                                </section>
                                <hr className="m-4" />
                                    <section className="mt-3 mb-3">
                                    <h4 className="text-uppercase mb-3">Speakers Bureau</h4>
                                    <p>The Fire Marshal’s office welcomes the opportunity to speak to any group about fire and life safety topics, fire safety in the home, exit drills in the home, holiday fire safety, wildfire safety and other topics.</p>
                                    <p>Feel free to call the Fire Marshal’s office at <Phone number="360-462-5020" /> to schedule a visit!</p>
                                    {/* https://www.co.mason.wa.us/community-services/fire-marshal/index.php */}
                                </section>
                            </Col>
                            <Col md={3}>
                                <Sidebar />
                            </Col>
                        </Row>
                    </Container>
                </Container>
                <GoToTop />
            </Container>
        </>
    );
}

export default FireMarshal;
