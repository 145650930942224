import React from 'react';

function Phone(props) {
    let is911 = false;
    if (props.number.indexOf('911') === 0) {
        is911 = true;
    }
    let is1800 = false;
    if (props.number.indexOf('1-8') === 0) {
        is1800 = true;
    }
    // Remove anything but phone numbers
    const numbers = props.number.replace(/[^0-9]/g, "").trim();
    let href = '';
    let display = '';
    let ext = '';
    let tel = '';
    if (is911) {
        href = '911';
        display = '911';
        tel = <a href={`tel:${href}`}>{display}</a>;
    } else if (!is1800 && !is911) {
        // Format 2061234567 -> 206-123-4567
        href = `${numbers.substr(0, 3)}-${numbers.substr(3, 3)}-${numbers.substr(6, 4)}`;
        display = `(${numbers.substr(0, 3)}) ${numbers.substr(3, 3)}-${numbers.substr(6, 4)}`;
        ext = numbers.length > 10 ? `ext. ${numbers.substr(10)}` : '';
        tel = <a href={`tel:1-${href}`}>{display} {ext}</a>;
    } else {
        href = `${numbers.substr(0, 1)}-${numbers.substr(1, 3)}-${numbers.substr(4, 3)}-${numbers.substr(7, 4)}`;
        display = `${numbers.substr(0, 1)}-${numbers.substr(1, 3)}-${numbers.substr(4, 3)}-${numbers.substr(7, 4)}`;
        // ext = numbers.length > 10 ? `ext. ${numbers.substr(10)}` : '';
        tel = <a href={`tel:${href}`}>{display}</a>;
    }
    
    
    const fax = display;
    return props.isFax ? fax : tel;
}

export default Phone;