/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Call911 from './Call911';
import BurnBan from './BurnBan';
import { NavLink } from "react-router-dom";

function Header() {
    return (
        <Container id="header-container" fluid className="ps-0 pe-0 g-0">
          <Row id="impt" className="g-0 align-items-center">
            <Col></Col>
            <Col sm="10">
              <Call911 />
            </Col>
            <Col></Col>
          </Row>
          <Row id="main-header" className="g-0 align-items-center">
            <Col sm="1" md="1" lg="2" xl="1"></Col>
            <Col sm="10" md="10" lg="8" xl="10" className="header">
                  <NavLink to="/">
                      <img
                        id="logo"
                        alt=""
                        src={process.env.PUBLIC_URL + `/images/logo.png`}
                        />{' '}
                      <img id="spellout" src={process.env.PUBLIC_URL + `/cmfeheader.png`} className="card-img-top" alt="..." />
                  </NavLink>
            </Col>
            <Col sm="1" md="1"lg="2" xl="1">
              <BurnBan />
              <div className="effect laertes">
                  <div className="buttons">
                      <a aria-label="Join us on Facebook" href="http://www.facebook.com/CMFE5" className="fb" title="Join us on Facebook"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                  </div>
              </div>
            </Col>
          </Row>
        </Container>
    );
}

export default Header;
