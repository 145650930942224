import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Breadcrumbs from "./Breadcrumbs";
import Sidebar from "./Sidebar";
import CommissionersDynamic from "../dynamic/CommisionersDynamic";

function Commissioners() {
  return (
    <Container className="main">
      <Container className="page">
        <Breadcrumbs />
        <Container>
          <Row>
            <Col md={9}>
              <h1>Commissioners</h1>
              <CommissionersDynamic />
            </Col>
            <Col md={3}>
              <Sidebar />
            </Col>
          </Row>
        </Container>
      </Container>
    </Container>
  );
}

export default Commissioners;
