import React from "react";
import { NavDropdown, Nav, Row, Col, Container } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
// import NavAboutUs from './NavAboutUs';
// import NavCommunity from './NavCommunity';
// import NavServices from './NavServices';

function NavigationLinks() {
  const about = [
    "about",
    "mission",
    "contact",
    "ourhistory",
    "currentbudget",
    "agreements",
    "ourstations",
    "chiefsmessage",
    "opportunities",
    "volunteer",
    "employment",
    "members",
    "commissioners2",
  ];
  // const commissioners = ["commissioners", "minutes", "events", "zoom-help"];
  const commissioners = ["commissioners", "minutes"];
  // const opportunities = ['opportunities', 'volunteer', 'members'];
  const safetycommunity = [
    "gallery",
    "calendar",
    "zoomhelp",
    "iaff-local-2394-scholarship",
    "burningregulations",
    "services",
    "fire-adapted-communities",
    "fireextinguishers",
    "protect-your-home",
    "safety-information",
    "burning-information",
    "for-kids",
  ];
  // const safety = ['safetyinformation'];
  const servicesprograms = [
    "drivewaymarker",
    "knoxboxes",
    "speakers",
    "station-tours",
    "smoke-alarm-installation",
    "ambulance-billing",
    "public-records-request",
    "cpr-first-aid",
  ];
  const { pathname } = useLocation();
  const path = pathname.split("/");
  const isActive = (group) => {
    return group.indexOf(path[path.length - 1]) > -1;
  };

  return (
    <Nav className="me-auto">
      {/* About */}
      <NavDropdown
        title="About"
        id="basic-nav-dropdown"
        className={`${isActive(about) ? "active" : ""} mega-dropdown`}
      >
        <Container>
          <Row>
            <Col md={6} className="sub-menu">
              <NavDropdown.Header className="mt-3">
                About CMFE
              </NavDropdown.Header>
              <NavDropdown.Item as={NavLink} end to="/about">
                About Us
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} end to="/about/mission">
                Our Mission, Vision, & Values
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} end to="/about/chiefsmessage">
                Fire Chief's Message
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Header className="mt-3">
                Organization
              </NavDropdown.Header>
              <NavDropdown.Item as={NavLink} end to="/about/commissioners2">
                Board of Commissioners
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} end to="/about/firemarshal">
                Fire Marshal
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} end to="/about/commandstaff">
                Command Staff
              </NavDropdown.Item>
            </Col>
            <Col md={6} className="sub-menu">
              <NavDropdown.Header className="mt-3">
                Opportunities
              </NavDropdown.Header>
              <NavDropdown.Item as={NavLink} end to="/opportunities/volunteer">
                Volunteer
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} end to="/opportunities/employment">
                Employment
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Header className="mt-3">
                Public Information
              </NavDropdown.Header>
              {/* <NavDropdown.Item as={NavLink} end to="/about/firemarshalfaq">Fire Marshal FAQ</NavDropdown.Item> */}
              <NavDropdown.Item as={NavLink} end to="/about/documents">
                Documents & Reports
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} end to="/about/forms">
                Forms
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} end to="/latest">
                Latest News
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} end to="/about/pressreleases">
                Press Releases
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Header className="mt-3">Contact</NavDropdown.Header>
              <NavDropdown.Item as={NavLink} end to="/about/contact">
                Contact Us
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} end to="/about/ourstations">
                Station Locations
              </NavDropdown.Item>
            </Col>
          </Row>
        </Container>
      </NavDropdown>

      {/* Commissioners */}
      <NavDropdown
        title="Commissioners"
        id="basic-nav-dropdown"
        className={`${isActive(commissioners) ? "active" : ""}`}
      >
        <NavDropdown.Item as={NavLink} end to="/commissioners">
          Board of Commissioners
        </NavDropdown.Item>
        <NavDropdown.Item as={NavLink} end to="/commissioners/minutes">
          Agendas & Minutes
        </NavDropdown.Item>
        {/* <NavDropdown.Item
          as={NavLink}
          end
          to="/commissioners/events"
         
        >
          Calendar
        </NavDropdown.Item> */}
        {/* <NavDropdown.Item
          as={NavLink}
          end
          to="/commissioners/zoom-help"
         
        >
          Zoom Help
        </NavDropdown.Item> */}
      </NavDropdown>

      {/* Community & Safety */}
      <NavDropdown
        title="Community & Safety"
        id="basic-nav-dropdown"
        className={isActive(safetycommunity) ? "active" : ""}
      >
        <Container>
          <Row>
            <Col md={6} className="sub-menu">
              <NavDropdown.Header className="mt-3">
                Community
              </NavDropdown.Header>
              {/* <NavDropdown.Item as={NavLink} end to="/community/calendar">Calendar</NavDropdown.Item> */}
              {/* <NavDropdown.Item as={NavLink} end to="/community/zoomhelp">Zoom Help</NavDropdown.Item> */}
              <NavDropdown.Item as={NavLink} end to="/community/gallery">
                Gallery
              </NavDropdown.Item>
              {/* <NavDropdown.Item as={NavLink} end to="/community/iaff-local-2394-scholarship">IAFF Local 2394 Scholarship Program</NavDropdown.Item> */}
            </Col>
            <Col md={6} className="sub-menu">
              <NavDropdown.Header className="mt-3">Safety</NavDropdown.Header>
              <NavDropdown.Item
                as={NavLink}
                end
                to="/community/burnrestrictions"
              >
                Burn Restrictions
              </NavDropdown.Item>
              <NavDropdown.Item
                as={NavLink}
                end
                to="/community/burning-information"
              >
                Burning Information
              </NavDropdown.Item>
              <NavDropdown.Item
                as={NavLink}
                end
                to="/community/burningregulations"
              >
                Burning Regulations
              </NavDropdown.Item>
              <NavDropdown.Item
                as={NavLink}
                end
                to="/community/fire-adapted-communities"
              >
                Fire Adapted Communities
              </NavDropdown.Item>
              <NavDropdown.Item
                as={NavLink}
                end
                to="/community/fireextinguishers"
              >
                Fire Extinguishers
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} end to="/community/for-kids">
                For Kids
              </NavDropdown.Item>
              <NavDropdown.Item
                as={NavLink}
                end
                to="/community/protect-your-home"
              >
                Protect Your Home
              </NavDropdown.Item>
              <NavDropdown.Item
                as={NavLink}
                end
                to="/community/safety-information"
              >
                Safety Information
              </NavDropdown.Item>
            </Col>
          </Row>
        </Container>
      </NavDropdown>

      {/* Services & Programs */}
      <NavDropdown
        title="Services & Programs"
        id="basic-nav-dropdown"
        className={isActive(servicesprograms) ? "active" : ""}
      >
        <NavDropdown.Item
          as={NavLink}
          end
          to="/services/countywide-emergency-alerts"
        >
          County-wide Emergency Alerts
        </NavDropdown.Item>
        <NavDropdown.Item as={NavLink} end to="/services/ambulance-billing">
          Ambulance Billing
        </NavDropdown.Item>
        <NavDropdown.Item as={NavLink} end to="/services/drivewaymarker">
          Driveway Marker
        </NavDropdown.Item>
        <NavDropdown.Item as={NavLink} end to="/services/knoxboxes">
          Knox Box
        </NavDropdown.Item>
        <NavDropdown.Item
          as={NavLink}
          end
          to="/services/public-records-request"
        >
          Public Records Request
        </NavDropdown.Item>
        <NavDropdown.Item
          as={NavLink}
          end
          to="/services/smoke-alarm-installation"
        >
          Smoke Alarm Installation
        </NavDropdown.Item>
        <NavDropdown.Item as={NavLink} end to="/services/speakers">
          Speakers
        </NavDropdown.Item>
        <NavDropdown.Item as={NavLink} end to="/services/station-tours">
          Station Tours
        </NavDropdown.Item>
        <NavDropdown.Item as={NavLink} end to="/services/cpr-first-aid">
          CPR/First Aid
        </NavDropdown.Item>
      </NavDropdown>

      {/* COVID-19 */}
      {/* <Nav.Link as={NavLink} end to="/covid19">COVID-19</Nav.Link> */}

      {/* <Nav.Link as={NavLink} end to="/firelevy">FIRE LEVY</Nav.Link> */}
      {/* <Nav.Link as={NavLink} end to="/ballot">
        FIVE-MEMBER BOARD BALLOT
      </Nav.Link> */}
    </Nav>
  );
}

export default NavigationLinks;
