import React, { useState, useEffect } from 'react';
import moment from 'moment';
import eventUtil from '../../utils/event';
import { Link } from "react-router-dom";

function UpcomingEvents() {
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        const url = 'https://www.cmfe.org/blog/wp-json/tribe/events/v1/events';
        fetch(url)
        .then(res => res.json())
        .then(cal => {
            if (cal.total > 0) {
                fetch(`${url}?per_page=${cal.total}`)
                .then(res => res.json())
                .then(cal => {
                    setEvents(cal.events.map(eventUtil.mapEvent));
                    setLoading(false);
                });
            } else {
                setLoading(false);
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            setLoading(false);
        });
        return () => {};
        
    }, []);

    const displayMultiNotTable = (start, end, event) => {
        const startDay = event.start && moment(event.start).format("dddd");
        const endDay = event.end && moment(event.end).format("dddd");
        return (
            <span className="small"><strong>{startDay} - {endDay}<br />{start} - {end}</strong></span>
        );
    };

    const displayOneNotTable = (event) => {
        const startDay = event.start && moment(event.start).format("dddd");
        const startDate = event.start && moment(event.start).format("MM/DD/YYYY");
        const startTime = event && event.start && moment(event.start).format("h:mm a");
        const venue = event && event.venue && event.venue.venue;
        let endTime = event && event.end ? ` - ${moment(event.end).format("h:mm a")}` : null;
        if (event && event.start && event.end) {
            if (moment(event.start).format("h:mm a") === moment(event.end).format("h:mm a")) {
                endTime = null;
            }
        }
        return (
            <>
                <span className="small"><strong>{startDay}</strong>, <strong>{startDate}</strong> at <strong>{startTime}{endTime}</strong>{venue ? `,` : ''}</span><br />
                <span className="small"><strong>{venue ? venue : ''}</strong></span>
            </>
        );
    };
    
    function displayEvent(event) {
        const start = event && event.start;
        const end = event && event.end;
        const isMultiDay = moment(start).format("MM/DD/YYYY") !== moment(end).format("MM/DD/YYYY");
        const formattedStart = moment(start).format("MM/DD/YYYY");
        const formattedEnd = moment(end).format("MM/DD/YYYY");
        let isZoomMeeting = false;
        if (event.website && event.website.length > 0 && event.website.toLowerCase().indexOf('zoom') > -1) {
            isZoomMeeting = true;
        }
        return (
            <div className="card-body text-center pb-0 pe-2 ps-2" key={event.id}>
                <p className="card-text">
                <i className="fa fa-calendar pe-2" aria-hidden="true" /> 
<span className="fw-bold">{event.title}</span>
                    <br />
                    {isMultiDay ? displayMultiNotTable(formattedStart, formattedEnd, event) : displayOneNotTable(event)}
                    {event.website && isZoomMeeting ? <><br /><i className="fa fa-video-camera small pe-1" aria-hidden="true" /> <span className="small"><a target="_blank" rel="noopener noreferrer" href={event.website} alt={`${event.title} website`}>Join the online Zoom meeting</a></span></> : null}
                </p>
            </div>
        );
    }

    function displayEvents() {
        let onlyTwo;
        if (events && events.length > 2) {
            onlyTwo = events.slice(0, 2);
        } else {
            onlyTwo = events;
        }
        return onlyTwo.map(displayEvent);
    }

    const renderNoEvents = () => {
        if (loading) return (
            <div className="d-flex justify-content-center mt-3 mb-3">
                <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
        return (
            <div className="card-body text-center p-0 pe-2 ps-2 pt-2" key="no-events">
                <p className="card-text">
                    <span className="fw-bolder">No upcoming events to display at this time</span>
                    <br />
                    {/* <i className="fa fa-question-circle small pe-1" aria-hidden="true" /> <Link to="/commissioners/zoom-help" className="text-center small mt-2 text-uppercase">Need help joining Zoom meetings?</Link> */}
                </p>
            </div>
        );
    };

    return (
        <div className="personalize p-0 pb-3">
            {/* <div className="card-header">
                <h6 className="card-title">Upcoming Events:</h6>
            </div> */}
            { events.length > 0 ? (
                <>
                    {displayEvents()}
                    <div className="text-center mt-3">
                        <i className="fa fa-info-circle small pe-1" aria-hidden="true" /> <Link to="/commissioners/events" className="text-center small mt-2">Learn more about upcoming events...</Link>
                        <br />
                        {/* <i className="fa fa-question-circle small pe-1" aria-hidden="true" /> <Link to="/commissioners/zoom-help" className="text-center small mt-2">Need help joining Zoom meetings?</Link> */}
                    </div>
                </>
             ) : renderNoEvents() }
        </div>
    );
}

export default UpcomingEvents;
