import React, { useContext } from "react";
import { Container, Button } from "react-bootstrap";
import { AppContext } from "../../AppContext";
import APressRelease from "./PressRelease";
import { NavLink } from "react-router-dom";

function AllContents() {
  const { pressReleasesData, pressReleasesLoading } = useContext(AppContext);

  if (pressReleasesLoading) {
    return (
      <>
        <Container className="border-shadow">
          <Container>
            <div className="d-flex justify-content-center mt-5 mb-3">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </Container>
        </Container>
      </>
    );
  }
  if (!pressReleasesData) return null;

  return (
    <>
      <Container className="border-shadow">
        <article>
          <h3>LATEST PRESS RELEASE</h3>
          {pressReleasesData.map((content, index) => (
            <article key={content.id}>
              <APressRelease content={content} />
              {index < pressReleasesData.length - 1 ? (
                <hr className="mt-5 mb-5" />
              ) : null}
            </article>
          ))}
        </article>
        <div className="text-center">
          <Button
            variant="dark"
            as={NavLink}
            to="/about/pressreleases"
            size="sm"
          >
            View more press releases...
          </Button>
        </div>
      </Container>
    </>
  );
}

export default AllContents;
