import React from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import {OverlayTrigger, Popover} from 'react-bootstrap';
import eventUtil from '../../utils/event';
import moment from "moment";
import dompurify from 'dompurify';

const sanitizer = dompurify.sanitize;

const Event = ({ event }) => {
    const venue = eventUtil.mapVenue(event.venue);
    const startTime = event && event.start && moment(event.start).format("h:mm a");
    let endTime = event && event.end ? ` - ${moment(event.end).format("h:mm a")}` : null;
    if (event && event.start && event.end) {
        if (moment(event.start).format("h:mm a") === moment(event.end).format("h:mm a")) {
            endTime = null;
        }
    }
    // let isZoomMeeting = false;
    // if (event.website && event.website.length > 0 && event.website.toLowerCase().indexOf('zoom') > -1) {
    //     isZoomMeeting = true;
    // }
        
    const popoverClickRootClose = (
        <Popover className="calendar-event" id="popover-trigger-click-root-close" style={{ zIndex: 10000 }} title={event.title}>
            <div className='m-2'>
                <h1><strong>{event.title}</strong></h1>
                {(venue && venue.venue) ? (
                    <>
                        <span><strong>Venue:</strong></span><br />
                        <span>{venue.venue && venue.venue.toString()}</span><br />
                        <span>{venue.address}<br />{venue.city ? `${venue.city}, ` : null}{venue.state} {venue.zip}</span><br />
                    </>
                ) : null}
                <span><strong>Time:</strong></span><br />
                <span>{startTime}{endTime}</span><br />
                <span><strong>Category:</strong></span><br />
                <span>{event.categories ? `${eventUtil.mapCategories(event.categories)}` : null}</span><br />
                {event.website ? <span><strong>Website:</strong> <a target="_blank" rel="noopener noreferrer" href={event.website} alt={`${event.title} website`}>{event.website}</a></span> : null}
                <span dangerouslySetInnerHTML={{__html: sanitizer(event.desc)}} />
                {/* {isZoomMeeting ? <div className="mt-2"><i className="fa fa-question-circle small pe-1" aria-hidden="true" /> <Link to="/commissioners/zoom-help" className="text-center mt-2">Need help joining Zoom meetings?</Link></div> : null} */}
            </div>
        </Popover>
    );

    return (
        <>
            <OverlayTrigger id="help" trigger="click" rootClose placement="right"
            overlay={popoverClickRootClose}>
                <span>{event.title}</span>
            </OverlayTrigger>
        </>
    );
};

Event.propTypes = {
    event: PropTypes.object
};

export default Event;
