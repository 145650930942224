import React, { useContext, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { AppContext } from '../../AppContext';

function AgendasAndMinutes() {
    const { pages, pagesAreLoading } = useContext(AppContext);
    const [agendasAndMinutes, setAgendasAndMinutes] = useState({});

    useEffect(() => {
        const getAgendasAndMinutes = () => {
            if (pages) {
                let agendasAndMinutesPage = {};
                pages.forEach((page) => {
                    if (page && page.link.indexOf('agendas-minutes') > -1) {
                        agendasAndMinutesPage = page;
                    }
                });
                setAgendasAndMinutes(agendasAndMinutesPage);
            }
        }
    
        if (pages) {
            getAgendasAndMinutes();
        }
    }, [pages]);

    if (pagesAreLoading) {
        return (
            <>
                <Container className="main align-content-start">
                    <Container>
                            <div className="d-flex justify-content-center mt-5 mb-3">
                                <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                    </Container>
                </Container>
            </>
            
        );
    }

    if (!pages) return null;

    return (
        <section className="m-3">
            { (agendasAndMinutes && agendasAndMinutes.content && agendasAndMinutes.content.rendered) ? (
                <div
                className="post-content mt-3"
                dangerouslySetInnerHTML={{__html: agendasAndMinutes.content.rendered}}
                />
                ) : null
            }
        </section>
    );
}

export default AgendasAndMinutes;
