import React from 'react';
import { Container, Image } from 'react-bootstrap';
import Breadcrumbs from './Breadcrumbs';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import GoToTop from '../GoToTop';

function AboutUs() {
    return (
        <Container className="main">
            <ScrollToTopOnMount />
            <Container className="page">
                <Breadcrumbs activePage="Our History" />
                <Container>
                    <h1>Our History</h1>
                    <article>
                        <p>Since we got our start in 1953, Central Mason Fire & EMS has seen a lot of notable incidents and achievements that led to a lot of changes for the department.</p>

                        <p>On March 13, 2000, we responded to Olympic Wood Products in John’s Prairie Industrial Area for a report of a fire.</p>

                        <p className="align-content-center">
                            <Image
                                fluid
                                rounded
                                src={process.env.PUBLIC_URL + `/images/olympic-wood-products.jpg`}
                                alt="" /></p>

                        <p>20-years ago, Mason County Fire District #5 (now Central Mason) responded to one of the largest fires in the history of Mason County. At 8:51 PM on March 13, 2000, Fire District #5 was dispatched to a commercial structure fire at Olympic Wood Products in the John’s Prairie Industrial Area, north of Shelton. Fire District #5 crews were on scene of the incident within three minutes of the initial dispatch and arrived to find a working fire in three wood drying kilns. Firefighters were able to save a good portion of the building, thanks to the well thought out strategy of now retired Assistant Chief Mike Snyder, who served as incident commander, and the hard work of 55 firefighters. Fire District #5 worked the incident for 19-hours and were assisted by almost every Mason County fire district and several from Kitsap County.</p>
                    </article>
                </Container>
            </Container>
            <GoToTop />
        </Container>
    );
}

export default AboutUs;
