import React from "react";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";

function Sidebar() {
  // const { hash, pathname } = useLocation();
  // const path = pathname.split('/');
  // const last = path[path.length - 1];

  // const isActive = (link, sub) => {
  //     if (hash && hash.length > 0 && hash.indexOf(sub) > -1) {
  //         return true;
  //     // } else if (hash.length === 0 && !sub && last.indexOf(link) > -1) {
  //     } else if (hash.length === 0 && !sub && last === link) {
  //         return true;
  //     }
  //     return false;
  // };

  return (
    <>
      <div id="sidebar" className="pt-4 pb-4 ps-3 pe-3 mb-3 bg-light shadow-sm">
        <Nav defaultActiveKey="/" className="flex-column">
          <h6 className="fw-bold mt-2" style={{ color: "#27468C" }}>
            Services & Programs
          </h6>
          <Nav.Link
            as={NavLink}
            end
            to="/services/countywide-emergency-alerts"
          >
            County-wide Emergency Alerts
          </Nav.Link>
          <Nav.Link
            as={NavLink}
            end
            to="/services/ambulance-billing"
          >
            Ambulance Billing
          </Nav.Link>
          <Nav.Link
            as={NavLink}
            end
            to="/services/drivewaymarker"
          >
            Driveway Marker
          </Nav.Link>
          {/* <Nav.Link as={NavLink} end to="/community" className="main-link">Community & Safety</Nav.Link> */}
          <Nav.Link
            as={NavLink}
            end
            to="/services/knoxboxes"
          >
            Knox Boxes
          </Nav.Link>
          <Nav.Link
            as={NavLink}
            end
            to="/services/permits"
          >
            Permits
          </Nav.Link>
          <Nav.Link
            as={NavLink}
            end
            to="/services/public-records-request"
          >
            Public Records Requests
          </Nav.Link>
          <Nav.Link
            as={NavLink}
            end
            to="/services/smoke-alarm-installation"
          >
            Smoke Alarm Installation
          </Nav.Link>
          <Nav.Link
            as={NavLink}
            end
            to="/services/speakers"
          >
            Speakers
          </Nav.Link>
          <Nav.Link
            as={NavLink}
            end
            to="/services/station-tours"
          >
            Station Tours
          </Nav.Link>

          {/* <Nav.Link as={NavLink} end to="/services/services-and-forms">Services & Forms</Nav.Link> */}

          {/* <h6 className="fw-bold mt-2" style={{ color: '#27468C'}}>Programs</h6> */}
          <Nav.Link
            as={NavLink}
            end
            to="/services/cpr-first-aid"
          >
            CPR/First Aid Classes
          </Nav.Link>
          {/* <Nav.Link as={NavLink} end to="/community/fire-adapted-communities">Fire Adapted Communities</Nav.Link> */}
          {/* <Nav.Link as={NavLink} end to="/community/smokealarms">Smoke Alarms</Nav.Link> */}
          {/* <Nav.Link as={NavLink} end to="/community/knoxboxes">Knox Boxes</Nav.Link> */}
          {/* <Nav.Link as={NavLink} end to="/community/drivewaymarker">Driveway Marker</Nav.Link> */}
          {/* <Nav.Link as={NavLink} end to="/community/fireextinguishers">Fire Extinguishers</Nav.Link> */}
          {/* <Nav.Link as={NavLink} end to="/community/permits">Permits</Nav.Link> */}
          {/* <Nav.Link as={NavLink} end to="/community/cpr-first-aid">CPR/First Aid Classes</Nav.Link> */}
          {/* <Nav.Link as={NavLink} end to="/community/protect-your-home">Protect Your Home</Nav.Link> */}
          {/* <Nav.Link as={NavLink} end to="/community/services-and-forms">Services & Forms</Nav.Link> */}
        </Nav>
      </div>
    </>
  );
}

export default Sidebar;
