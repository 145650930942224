import React, { useContext, useEffect, useState, useRef } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { AppContext } from "../../AppContext";
import moment from "moment";
import { useParams } from "react-router-dom";
import Breadcrumbs from "./Breadcrumbs";
import ScrollToTopOnMount from "../ScrollToTopOnMount";
import GoToTop from "../GoToTop";
import Sidebar from "../about/Sidebar";

// /content/:id
function Content() {
  const { contentData } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState("");
  const [modified, setModified] = useState("");
  const [content, setContent] = useState("");

  const matchingPost = useRef(null);
  const { id } = useParams();

  useEffect(() => {
    setLoading(true);

    if (contentData && id) {
      const match = contentData.filter((aPost) => `${aPost.id}` === id);
      if (match.length > 0 && match[0]) {
        matchingPost.current = match[0];
        if (match[0].title) {
          setTitle(match[0].title.rendered);
        }
        setModified(match[0].date);
        if (match[0].date) {
          setContent(match[0].content.rendered);
        }
        setLoading(false);
      }
    }
  }, [contentData, id, matchingPost]);

  if ((!contentData || !matchingPost.current) && !loading) return null;

  if (loading)
    return (
      <Container className="main align-content-start">
        <ScrollToTopOnMount />
        {/* <Breadcrumbs activePage="Events" /> */}
        <Container className="page article-content posts">
          <div className="d-flex justify-content-center mt-3 mb-3">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </Container>
      </Container>
    );

  return (
    <Container className="main align-content-start latest">
      <Container className="article-content posts">
        <ScrollToTopOnMount />
        <Breadcrumbs activePage={title} />
        <Container>
          <Row>
            <Col md={9}>
              <h1 dangerouslySetInnerHTML={{ __html: title }} />
              <div className="d-flex">
                <article
                  style={{ width: "100%", overflow: "auto" }}
                  className="mb-5"
                >
                  <div className="post-meta d-flex">
                    {modified ? (
                      <small className="me-2 date text-secondary">
                        <strong>Published:</strong>{" "}
                        {moment(modified).format(
                          "dddd, MMMM Do YYYY, h:mm:ss a"
                        )}
                      </small>
                    ) : null}
                  </div>
                  <div
                    className="post-content mt-3"
                    dangerouslySetInnerHTML={{ __html: content }}
                  />
                </article>
              </div>
            </Col>
            <Col md={3}>
              <Sidebar />
            </Col>
          </Row>
        </Container>
        <GoToTop />
      </Container>
      {/* <Container id="related-posts">
                <div className="row justify-content-center p-5">
                <h4 className="text-uppercase mb-5">Check Out These Other News</h4>
                    <Container>
                        <Contents mainPostId={id} />
                    </Container>
                </div>
            </Container> */}
    </Container>
  );
}

export default Content;
