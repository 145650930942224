import React from 'react';
import { Carousel } from 'react-bootstrap';
// import { Parallax } from 'react-scroll-parallax';

function HomeCarousel() {
    return (
        <>
            {/* <Parallax className="home-carousel" y={[30, -30]}> */}
                <Carousel id="home-carousel">
                    {/* <Carousel.Item interval={1000}>
                        <img
                            className="d-block w-100"
                            src={process.env.PUBLIC_URL + `/images/carousel/slider1-700.png`}
                            alt=""
                        />
                    </Carousel.Item> */}
                    {/* <Carousel.Item interval={500}>
                        <img
                            className="d-block w-100"
                            src={process.env.PUBLIC_URL + `/images/carousel/slider2-700.png`}
                            alt=""
                        />
                    </Carousel.Item> */}
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={process.env.PUBLIC_URL + `/images/carousel/slider3-700.png`}
                            alt=""
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={process.env.PUBLIC_URL + `/images/carousel/slider4-700.png`}
                            alt=""
                        />
                    </Carousel.Item>
                    {/* <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={process.env.PUBLIC_URL + `/images/carousel/slider5-700.png`}
                            alt=""
                        />
                    </Carousel.Item> */}
                    {/* <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={process.env.PUBLIC_URL + `/images/carousel/slider6-700.png`}
                            alt=""
                        />
                    </Carousel.Item> */}
                    <Carousel.Item interval={1000}>
                        <img
                            className="d-block w-100"
                            src={process.env.PUBLIC_URL + `/images/carousel/IMG_1394_1024.png`}
                            alt=""
                        />
                    </Carousel.Item>
                    <Carousel.Item interval={1000}>
                        <img
                            className="d-block w-100"
                            src={process.env.PUBLIC_URL + `/images/carousel/thumb_IMG_0223_1024.png`}
                            alt=""
                        />
                    </Carousel.Item>
                    <Carousel.Item interval={1000}>
                        <img
                            className="d-block w-100"
                            src={process.env.PUBLIC_URL + `/images/carousel/thumb_IMG_3050_1024.png`}
                            alt=""
                        />
                    </Carousel.Item>
                    <Carousel.Item interval={1000}>
                        <img
                            className="d-block w-100"
                            src={process.env.PUBLIC_URL + `/images/carousel/thumb_IMG_3645_1024.png`}
                            alt=""
                        />
                    </Carousel.Item>
                    <Carousel.Item interval={1000}>
                        <img
                            className="d-block w-100"
                            src={process.env.PUBLIC_URL + `/images/carousel/thumb_IMG_4305_1024.png`}
                            alt=""
                        />
                    </Carousel.Item>
                    <Carousel.Item interval={1000}>
                        <img
                            className="d-block w-100"
                            src={process.env.PUBLIC_URL + `/images/carousel/call.png`}
                            alt=""
                        />
                    </Carousel.Item>
                    <Carousel.Item interval={1000}>
                        <img
                            className="d-block w-100"
                            src={process.env.PUBLIC_URL + `/images/carousel/IMG_4510.png`}
                            alt=""
                        />
                    </Carousel.Item>
                    <Carousel.Item interval={1000}>
                        <img
                            className="d-block w-100"
                            src={process.env.PUBLIC_URL + `/images/carousel/IMG_4512.png`}
                            alt=""
                        />
                    </Carousel.Item>
                    <Carousel.Item interval={1000}>
                        <img
                            className="d-block w-100"
                            src={process.env.PUBLIC_URL + `/images/carousel/rig-1.png`}
                            alt=""
                        />
                    </Carousel.Item>
                </Carousel>
            {/* </Parallax> */}
        </>
    );
}

export default HomeCarousel;
