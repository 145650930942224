import React, { useContext, useState, useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import ScrollToTopOnMount from "../ScrollToTopOnMount";
import GoToTop from "../GoToTop";
import { AppContext } from "../../AppContext";

function CommandStaff() {
  const { pages, pagesAreLoading } = useContext(AppContext);
  const [pageData, setPageData] = useState({});
  useEffect(() => {
    const getPage = () => {
      if (pages) {
        let thisPage = {};
        pages.forEach((page, index) => {
          if (page && page.link.indexOf("command-staff") > -1) {
            thisPage = page;
          }
        });
        setPageData(thisPage);
      }
    };

    if (pages) {
      getPage();
    }
  }, [pages]);

  if (pagesAreLoading) {
    return (
      <Container>
        <div className="d-flex justify-content-center mt-5 mb-3">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </Container>
    );
  }

  if (!pages) return null;
  return (
    <>
      <Container id="command-staff" className="main">
        <ScrollToTopOnMount />
        <Container className="page">
          <Breadcrumb>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/about" }}>
              About
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Command Staff</Breadcrumb.Item>
          </Breadcrumb>
          <Container>
            <Row>
              <Col md={9}>
              <h1 dangerouslySetInnerHTML={{__html: pageData?.title?.rendered}} />
              {pageData && pageData.content && pageData.content.rendered ? (
                <div
                  className="mt-3"
                  dangerouslySetInnerHTML={{
                    __html: pageData.content.rendered,
                  }}
                />
              ) : null}
              </Col>
              <Col md={3}>
                <Sidebar />
              </Col>
            </Row>
          </Container>
        </Container>
        <GoToTop />
      </Container>
    </>
  );
}

export default CommandStaff;
