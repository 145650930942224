import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Breadcrumbs from './Breadcrumbs';
import Sidebar from './Sidebar';
import ExternalLink from '../shared/ExternalLink';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import GoToTop from '../GoToTop';

function SafetyInformation() {
    return (
        <>
            <Container id="safety-information" className="main">
                <ScrollToTopOnMount />
                <Container className="page">
                    <Breadcrumbs activePage="For Kids" />
                    <Container>
                        <Row>
                            <Col md={9}>
                                <h1>For Kids</h1>
                                <section className="mt-3 mb-3">
                                    <ul>
                                        <li><ExternalLink href="https://smokeybear.com/en/about-wildland-fire" label='Smokey Bear Wildfire Information - "Stop Wildfires"' />
                                        </li>
                                        <li><a href={process.env.PUBLIC_URL + `/files/How-to-Make-a-Home-Fire-Escape-Plan.pdf`} alt={`How to Make a Fire Escape Plan`} target="_blank" rel="noopener noreferrer">How to Make a Fire Escape Plan</a></li>
                                        <li><ExternalLink href="https://www.homeadvisor.com/r/fire-prevention-preparedness-and-recovery/#kids" label="Fire Prevention & Preparedness" /></li>
                                        {/* <embed src={process.env.PUBLIC_URL + `/files/How-to-Make-a-Home-Fire-Escape-Plan.pdf`} frameBorder="0" width="100%" height="680px" /> */}
                                    </ul>
                                    <div className="text-center">
                                        <iframe title=" " width="560" height="315" src="https://www.youtube.com/embed/WUqjNF_DNkQ" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullscreen></iframe>
                                    </div>
                                </section>
                            </Col>
                            <Col md={3}>
                                <Sidebar />
                            </Col>
                        </Row>
                    </Container>
                </Container>
                <GoToTop />
            </Container>
        </>
    );
}

export default SafetyInformation;
