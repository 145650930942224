import React, { useState, useEffect, useContext } from "react";
import { Container, Col, Row } from "react-bootstrap";
import Breadcrumbs from "./Breadcrumbs";
import Sidebar from "./Sidebar";
import ScrollToTopOnMount from "../ScrollToTopOnMount";
import GoToTop from "../GoToTop";
import { AppContext } from "../../AppContext";

function CountywideEmergencyAlerts() {
  const { pages, pagesAreLoading } = useContext(AppContext);
  const [pageData, setPageData] = useState({});

  useEffect(() => {
    const getPage = () => {
      if (pages) {
        let thisPage = {};
        pages.forEach((page, index) => {
          if (page && page.link.indexOf("county-wide-emergency-alerts") > -1) {
            thisPage = page;
          }
        });
        setPageData(thisPage);
      }
    };

    if (pages) {
      getPage();
    }
  }, [pages]);

  if (pagesAreLoading) {
    return (
      <Container id="countywide-emergency-alerts" className="main">
        <ScrollToTopOnMount />
        <Container className="page">
          <Breadcrumbs activePage="County-wide Emergency Alerts" />
          <Container>
            <Row>
              <Col md={9}>
                <div className="d-flex justify-content-center mt-5 mb-3">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              </Col>
              <Col md={3}>
                <Sidebar />
              </Col>
            </Row>
          </Container>
        </Container>
        <GoToTop />
      </Container>
    );
  }

  if (!pages) return null;

  return (
    <>
      {pageData && pageData.content && pageData.content.rendered ? (
        <Container id="countywide-emergency-alerts" className="main">
          <ScrollToTopOnMount />
          <Container className="page">
            <Breadcrumbs activePage="County-wide Emergency Alerts" />
            <Container>
              <Row>
                <Col md={9}>
                  <div
                    className="mt-3"
                    dangerouslySetInnerHTML={{
                      __html: pageData.content.rendered,
                    }}
                  />
                </Col>
                <Col md={3}>
                  <Sidebar />
                </Col>
              </Row>
            </Container>
          </Container>
          <GoToTop />
        </Container>
      ) : null}
    </>
  );
}

export default CountywideEmergencyAlerts;
