import React from 'react';

function Email(props) {
    const email = (props.email && props.email.trim()) || '';
    const label = (props.label && props.label.trim()) || '';
    if (label) {
        return <a href={`mailto:${email}`}>{label}</a>;
    }
    return <a href={`mailto:${email}`}>{email}</a>;
}

export default Email;
