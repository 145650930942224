import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Breadcrumbs from './Breadcrumbs';
import Sidebar from './Sidebar';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import GoToTop from '../GoToTop';

function FireExtinguishers() {

    return (
        <>
            <Container id="fire-marshal" className="main">
                <ScrollToTopOnMount />
                <Container className="page">
                    <Breadcrumbs activePage="Fire Extinguishers" />
                    <Container>
                        <Row>
                            <Col md={9}>
                                <h1>Fire Extinguishers</h1>
                                <section className="mt-3 mb-3">
                                    <h4 className="mb-3">Using a Fire Extinguisher</h4>
                                    <p>Knowing how to operate an extinguisher quickly is very important, when you need one you won’t have time to read the instructions.  Remember the word P.A.S.S. – Pull. Aim. Squeeze. Sweep</p>
                                    <ul>
                                        <li>Pull the pin - Some extinguishers require unlocking a latch or pressing a puncture lever.</li>
                                        <li>Aim – Point the extinguisher nozzle (or its horn or hose) at the base of the fire.</li>
                                        <li>Squeeze the handle – This releases the extinguishing agent.</li>
                                        <li>Sweep side to side at the base of fire – continue until the fire appears to be out</li>
                                    </ul>
                                </section>
                                <hr className="m-4" />
                                <section className="mt-3 mb-3">
                                    <h4 className="mb-3">Types of Fire Extinguishers</h4>
                                    <p>There are five classifications of fire extinguishers, each designed to put out a specific type of fire. Some Fire Extinguishers come rated for multiple classifications of fires. Using the wrong kind of fire extinguisher can be extremely hazardous and dangerous.</p>
                                    <ul>
                                        <li>Class A – for fires that contain ordinary combustibles such as paper, wood, cloth, plastics and rubber</li>
                                        <li>Class B – for fires that contain flammable liquids like oil, grease and gasoline</li>
                                        <li>Class C – for fires involving energized electrical equipment such as tools or appliances</li>
                                        <li>Class D – for fires involving combustible metals (such as magnesium)</li>
                                        <li>Class K – for fires involving vegetable-based oils used in a deep frying (used in commercial kitchens)</li>
                                        <li>Class A-B-C – a multi classification fire extinguisher, most common in residential buildings. </li>
                                    </ul>
                                </section>
                                <hr className="m-4" />
                                <section className="mt-3 mb-3">
                                    <h4 className="mb-3">When NOT to attempt to extinguish a fire with a fire extinguisher</h4>
                                    <ul>
                                        <li>If the fire could block your only exit</li>
                                        <li>If the fire is spreading too quickly</li>
                                        <li>If the fire is too large</li>
                                        <li>If your house is filling with smoke</li>
                                        <li>If you are unsure how to use a fire extinguisher</li>
                                    </ul>
                                </section>
                                <hr className="m-4" />
                                <section className="mt-3 mb-3">
                                    <h4 className="mb-3">Important to remember</h4>
                                    <ul>
                                        <li>Only use a fire extinguisher after you have called 911 and all members of your home have been alerted to the fire. Make sure to stay low to avoid the smoke!</li>
                                        <li>Once a fire has started you only have seconds to extinguish the fire, a fire burning for just a minute can triple it’s original size.</li>
                                        <li>If there is a frying pan fire, use a lid to cover the pan this will cut off the oxygen to fire and put it out. Using a A-B-C fire extinguisher will not extinguish a grease fire.</li>
                                    </ul>
                                </section>
                            </Col>
                            <Col md={3}>
                                <Sidebar />

                            </Col>
                        </Row>
                    </Container>
                </Container>
                <GoToTop />
            </Container>
        </>
    );
}

export default FireExtinguishers;
