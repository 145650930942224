import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { AppContext } from "../../AppContext";
import Sidebar from "./Sidebar";
import ScrollToTopOnMount from "../ScrollToTopOnMount";
import GoToTop from "../GoToTop";
import Breadcrumbs from "./Breadcrumbs";

function AboutDynamic() {
  const { pages, pagesAreLoading } = useContext(AppContext);
  const [pageData, setPageData] = useState({});
  useEffect(() => {
    const getPage = () => {
      if (pages) {
        let thisPage = {};
        pages.forEach((page, index) => {
          if (page && page.link.indexOf("about-us") > -1) {
            thisPage = page;
          }
        });
        setPageData(thisPage);
      }
    };

    if (pages) {
      getPage();
    }
  }, [pages]);

  if (pagesAreLoading) {
    return (
      <Container>
        <div className="d-flex justify-content-center mt-5 mb-3">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </Container>
    );
  }

  if (!pages) return null;

  return (
    <>
      <Container id="about" className="main">
        <ScrollToTopOnMount />
        <Container className="page">
          <Breadcrumbs />
          <Container>
            <Row>
              <Col md={9}>
                <h1 dangerouslySetInnerHTML={{__html: pageData?.title?.rendered}} />
                {pageData?.content?.rendered ? (
                  <div
                    className="mt-3"
                    dangerouslySetInnerHTML={{
                      __html: pageData.content.rendered,
                    }}
                  />
                ) : null}
              </Col>
              <Col md={3}>
                <Sidebar />
              </Col>
            </Row>
          </Container>
        </Container>
        <GoToTop />
      </Container>
    </>
  );
}

export default AboutDynamic;
