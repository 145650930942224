import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Breadcrumbs from './Breadcrumbs';
import Sidebar from './Sidebar';
import ExternalLink from '../shared/ExternalLink';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import GoToTop from '../GoToTop';

function SafetyInformation() {
    return (
        <>
            <Container id="safety-information" className="main">
                <ScrollToTopOnMount />
                <Container className="page">
                    <Breadcrumbs activePage="Safety Information" />
                    <Container>
                        <Row>
                            <Col md={9}>
                                <h1>Safety Information</h1>
                                <section className="mt-3 mb-3">
                                    <ul>
                                        <li>Smoke Alarms - Don't Wait, Check the Date
                                            <embed src={process.env.PUBLIC_URL + `/files/infographic_2016_fpw_custom.pdf`} frameBorder="0" width="100%" height="680px" className="shadow-sm" />
                                        </li>
                                        <li><ExternalLink href="https://www.kidde.com/home-safety/en/us/fire-safety/placement-of-smoke-alarms/" label="Proper Placement of Smoke Alarms" /></li>
                                        <li><ExternalLink href="https://www.realtor.com/advice/home-improvement/how-to-install-fire-sprinklers/" label="How to Safely Install Fire Sprinklers" /></li>
                                        <li><ExternalLink href="http://inciweb.nwcg.gov/state/49/" label="Wildfires Currently Burning - Information Map" /></li>
                                        <li><ExternalLink href="https://www.orcaa.org/residential-programs/burn-bans/" label="ORCAA Burn Ban Information" /></li>
                                        <li><ExternalLink href="https://www.orcaa.org/outdoor-burning/" label="ORCAA Burn Permit" /></li>
                                        <li>
                                            Reducing Wildfire Risk in the Home Ignition Zone - Firewise
                                            <embed src={process.env.PUBLIC_URL + `/files/ReducingWildfireRisks.pdf`} frameBorder="0" width="100%" height="680px" className="shadow-sm" />
                                        </li>
                                        <li>
                                            Carbon Monoxide Safety - NFPA
                                            <embed src={process.env.PUBLIC_URL + `/files/COSafety.pdf`} frameBorder="0" width="100%" height="680px" className="shadow-sm" />
                                        </li>
                                    </ul>
                                </section>
                            </Col>
                            <Col md={3}>
                                <Sidebar />
                            </Col>
                        </Row>
                    </Container>
                </Container>
                <GoToTop />
            </Container>
        </>
    );
}

export default SafetyInformation;
