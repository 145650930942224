import React from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';

function Breadcrumbs({ activePage }) {
    return (
        <Breadcrumb>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>Home</Breadcrumb.Item>
            { activePage ? <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/opportunities" }}>Opportunities</Breadcrumb.Item> : null }
            { !activePage ? <Breadcrumb.Item active >Opportunities</Breadcrumb.Item> : null }
            { activePage ? <Breadcrumb.Item active>{activePage}</Breadcrumb.Item> : null }
        </Breadcrumb>
    );
}

export default Breadcrumbs;
