import React from 'react';
import { Container, Col, Row, Alert } from 'react-bootstrap';
import Breadcrumbs from './Breadcrumbs';
import Sidebar from './Sidebar';
import Phone from '../shared/Phone';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import GoToTop from '../GoToTop';

function SmokeAlarms() {
    return (
        <>
            <Container id="smokealarms" className="main">
                <ScrollToTopOnMount />
                <Container className="page">
                    <Breadcrumbs activePage="Smoke Alarm Installation" />
                    <Container>
                        <Row>
                            <Col md={9}>
                                <h1>Smoke Alarm Installation</h1>
                                <section className="mt-3 mb-3">
                                    <p>The majority of fatal home fires happen at night, and the home didn’t have smoke alarms. When a fire starts in the middle of the night, the smoke won’t always wake you up and can put you into a deeper sleep. Home smoke alarms can wake you up in time to escape. Smoke alarms are inexpensive, easy to install and can save your life.</p>
                                    <p>Choosing the right fire alarm – buy an approved smoke alarm that carries the label of an independent testing lab.</p>
                                    <p>Installation locations – install a smoke alarm in every living area of each floor (including the basement). Also install a smoke alarm inside and outside of each bedroom door. Smoke rises in a fire, you should install the fire alarm at the highest point of each room.</p>
                                    <p>Testing Alarms – You should test the fire alarms weekly, to make sure they work and that you can hear them in each bedroom.</p>
                                    <p>Replace batteries – at least twice a year. A good way to remember this is to change the batteries when you change your clocks.</p>
                                    <p>Replace Alarms Every 10 years – smoke alarms wear out. Know the age of your alarms (look at back of alarm for manufacture date) and replace alarms that are more than 10 years old. This applies to both battery powered and hardwired units.</p>
                                    <Alert className="text-center p-2 m-3" variant="warning">If you need help installing or getting smoke detectors call the Fire Marshal’s Office at <Phone number="360-462-5020" />.</Alert>
                                </section>
                            </Col>
                            <Col md={3}>
                                <Sidebar />

                            </Col>
                        </Row>
                    </Container>
                </Container>
                <GoToTop />
            </Container>
        </>
    );
}

export default SmokeAlarms;
