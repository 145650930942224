
import React from 'react';
import { Container, Image, Row, Col, Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

function FrontPage() {
    return (
        <Container id="front-page" className="pt-4 pb-4 border-shadow mt-3 mb-4" style={{ borderRadius: '0' }}>
            <Row>
                <Col xs={12} md={4} className="text-center">
                    <Image
                        src={process.env.PUBLIC_URL + `/images/square/frontpage1.png`}
                        roundedCircle
                        thumbnail
                        width={250}
                        height={250}
                        className="mx-auto d-block mb-3"
                    />
                    <h5 className="text-center text-uppercase fw-bolder">Volunteer</h5>
                    <p className="text-start">
                        Become an asset to your family, friends and community by volunteering. Being a volunteer fire fighter is very rewarding. Click for more information.
                  </p>
                    <Button variant="dark" as={NavLink} to="/opportunities/volunteer" >Learn more...</Button>
                </Col>
                <Col xs={12} md={4} className="text-center">
                    <Image
                        src={process.env.PUBLIC_URL + `/images/square/frontpage2.png`}
                        roundedCircle
                        thumbnail
                        width={250}
                        height={250}
                        className="mx-auto d-block mb-3"
                    />
                    <h5 className="text-center text-uppercase fw-bolder">Events</h5>
                    <p className="text-start">
                        Throughout the year we offer events to the community and training programs. Please look over our calendar of events for something you might be interested in.
                  </p>
                    <Button variant="dark" as={NavLink} to="/community/calendar" >Learn more...</Button>
                </Col>
                <Col xs={12} md={4} className="text-center">
                    <Image
                        src={process.env.PUBLIC_URL + `/images/square/frontpage3.png`}
                        roundedCircle
                        thumbnail
                        width={250}
                        height={250}
                        className="mx-auto d-block mb-3"
                    />
                    <h5 className="text-center text-uppercase mb-2 fw-bolder">Commissioners</h5>
                    <p className="text-start">
                        We maintain the commissioner minutes and agendas along with current commissioner information here. All documents are in PDF format for easy access.
                    </p>
                    <Button variant="dark" as={NavLink} to="/commissioners" >Learn more...</Button>
                </Col>
            </Row>
        </Container>
    );
}

export default FrontPage;
