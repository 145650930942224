import React, { useContext, useEffect, useState } from 'react';
import fileList from './minutes.json';
import { Container } from 'react-bootstrap';
import { AppContext } from '../../AppContext';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import GoToTop from '../GoToTop';
    // Minutes
  // id: 1589
  // link: https://cmfe.org/blog/agendas-minutes/

function Minutes({ year }) {
    const { pages, pagesAreLoading } = useContext(AppContext);
    const [minutePages, setMinutePages] = useState({});

    useEffect(() => {
        const getAllMinutes = () => {
            const minutesPageId = 1589;
            if (pages) {
                const pageCollectionPerYear = {};
                pages.forEach((page, index) => {
                    if (page && page.parent === minutesPageId) {
                        pageCollectionPerYear[page.title.rendered] = page;
                    }
                });
                setMinutePages(pageCollectionPerYear);
            }
        }
    
        if (pages) {
            getAllMinutes();
        }
    }, [pages]);

    if (pagesAreLoading) {
        return (
            <>
                <Container className="main align-content-start">
                    <ScrollToTopOnMount />
                    <Container>
                            <div className="d-flex justify-content-center mt-5 mb-3">
                                <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                    </Container>
                    <GoToTop />
                </Container>
            </>
            
        );
    }

    if (!pages) return null;

    let aYear = '';
    function displayLink(fileName) {
        return <li key={fileName}><a href={process.env.PUBLIC_URL + `/files/minutes/${year}/${fileName}`} alt={`Minutes from ${fileName}`} target="_blank" rel="noopener noreferrer">{fileName}</a></li>;
    }
    function displayLinks(group) {
        aYear = group.year;
        const list = (group && group.list) || [];
        if (aYear !== year) {
            return null;
        }
        return (
            <div id="meeting-minutes" key={year}>
                <div>
                    <h4>{aYear}</h4>
                    { minutePages[year] && minutePages[year].content && minutePages[year].content.rendered ? (
                        <div
                        className="post-content mt-3"
                        dangerouslySetInnerHTML={{__html: minutePages[year].content.rendered}}
                        />
                        ) : (
                        <ul>
                        {list.map(displayLink)}
                        </ul>
                        )
                    }
                </div>
            </div>
        );
    }

    return (
        <div>
            <div className="row">
                {fileList.map(displayLinks)}
            </div>
        </div>
    );
}

export default Minutes;
