import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Breadcrumbs from './Breadcrumbs';
import Sidebar from './Sidebar';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import GoToTop from '../GoToTop';
import Phone from '../shared/Phone';

function StationTours() {
    return (
        <>
            <Container id="station-tours" className="main">
                <ScrollToTopOnMount />
                <Container className="page">
                    <Breadcrumbs activePage="Station Tours" />
                    <Container>
                        <Row>
                            <Col md={9}>
                                <h1>Station Tours</h1>
                                <section className="mt-3 mb-5">
                                    <p>Did you know CMFE offers station tours at our headquarters station in downtown Shelton? Come meet our firefighters, check out our fire trucks, and meet Kindle the fire dog. To schedule a tour call, <Phone number="360-426-5533" />.</p>
                                </section>
                            </Col>
                            <Col md={3}>
                                <Sidebar />

                            </Col>
                        </Row>
                    </Container>
                </Container>
                <GoToTop />
            </Container>
        </>
    );
}

export default StationTours;
