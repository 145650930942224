import React from "react";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";

function Sidebar() {
  // const { hash, pathname } = useLocation();
  // const path = pathname.split('/');
  // const last = path[path.length - 1];

  // const isActive = (link, sub) => {
  //     if (hash && hash.length > 0 && hash.indexOf(sub) > -1) {
  //         return true;
  //     // } else if (hash.length === 0 && !sub && last.indexOf(link) > -1) {
  //     } else if (hash.length === 0 && !sub && last === link) {
  //         return true;
  //     }
  //     return false;
  // };

  return (
    <>
      <div id="sidebar" className="pt-4 pb-4 ps-3 pe-3 mb-3 bg-light shadow-sm">
        <Nav defaultActiveKey="/" className="flex-column">
          <h6 className="fw-bold mt-2" style={{ color: "#27468C" }}>
            Community
          </h6>
          {/* <Nav.Link as={NavLink} end to="/community" className="main-link">Community & Safety</Nav.Link> */}
          {/* <Nav.Link as={NavLink} end to="/community/calendar">Calendar</Nav.Link>
                    <Nav.Link as={NavLink} end to="/community/zoomhelp">Zoom Help</Nav.Link> */}
          <Nav.Link as={NavLink} end to="/community/gallery">
            Gallery
          </Nav.Link>
          {/* <Nav.Link as={NavLink} end to="/community/iaff-local-2394-scholarship">IAFF Local 2394 Scholarship Program</Nav.Link> */}

          <h6 className="fw-bold mt-2" style={{ color: "#27468C" }}>
            Safety
          </h6>

          <Nav.Link as={NavLink} end to="/community/burnrestrictions">
            Burn Restrictions
          </Nav.Link>
          <Nav.Link as={NavLink} end to="/community/burning-information">
            Burning Information
          </Nav.Link>
          <Nav.Link as={NavLink} end to="/community/burningregulations">
            Burning Regulations
          </Nav.Link>
          <Nav.Link as={NavLink} end to="/community/fire-adapted-communities">
            Fire Adapted Communities
          </Nav.Link>
          <Nav.Link as={NavLink} end to="/community/fireextinguishers">
            Fire Extinguishers
          </Nav.Link>
          <Nav.Link as={NavLink} end to="/community/for-kids">
            For Kids
          </Nav.Link>
          <Nav.Link as={NavLink} end to="/community/protect-your-home">
            Protect Your Home
          </Nav.Link>
          <Nav.Link as={NavLink} end to="/community/safety-information">
            Safety Information
          </Nav.Link>
        </Nav>
      </div>
    </>
  );
}

export default Sidebar;
