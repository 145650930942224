import React from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';

function Breadcrumbs({ activePage }) {
    return (
        <Breadcrumb>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>Home</Breadcrumb.Item>
            {/* <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/about" }}>Community & 
            Safety</Breadcrumb.Item> */}
            <Breadcrumb.Item active>Community & Safety</Breadcrumb.Item>
            <Breadcrumb.Item active>{activePage}</Breadcrumb.Item>
        </Breadcrumb>
    );
}

export default Breadcrumbs;
