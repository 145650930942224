import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ScrollToTopOnMount from "../ScrollToTopOnMount";
import GoToTop from "../GoToTop";
import Sidebar from "../about/Sidebar";
import Breadcrumbs from "../about/Breadcrumbs";
import EmploymentDynamic from "../dynamic/EmploymentDynamic";

function Opportunities() {
  return (
    <Container className="main">
      <ScrollToTopOnMount />
      <Container className="page">
        <Breadcrumbs activePage="Employment" />
        <Container>
          <Row>
            <Col md={9}>
              <h1>Employment</h1>

              <EmploymentDynamic />
            </Col>
            <Col md={3}>
              <Sidebar />
            </Col>
          </Row>
        </Container>
      </Container>
      <GoToTop />
    </Container>
  );
}

export default Opportunities;
