import React, { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { AppContext } from "../../AppContext";

function VolunteerDynamic() {
  const { pages, pagesAreLoading } = useContext(AppContext);
  const [pageData, setPageData] = useState({});

  useEffect(() => {
    const getPage = () => {
      if (pages) {
        let thisPage = {};
        pages.forEach((page, index) => {
          if (page && page.link.indexOf("volunteer") > -1) {
            thisPage = page;
          }
        });
        setPageData(thisPage);
      }
    };

    if (pages) {
      getPage();
    }
  }, [pages]);

  if (pagesAreLoading) {
    return (
      <Container>
        <div className="d-flex justify-content-center mt-5 mb-3">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </Container>
    );
  }

  if (!pages) return null;

  return (
    <>
      {pageData && pageData.content && pageData.content.rendered ? (
        <div
          className="mt-3"
          dangerouslySetInnerHTML={{
            __html: pageData.content.rendered,
          }}
        />
      ) : null}
    </>
  );
}

export default VolunteerDynamic;
