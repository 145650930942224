import React, { useState, useEffect } from "react";
import { Image, Col, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import moment from "moment";
import axios from "axios";

// content on home
function Content({ content }) {
  const [media, setMedia] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (content && content.featured_media) {
      const mediaUrl = `https://cmfe.org/blog/wp-json/wp/v2/media/${content.featured_media}`;
      axios
        .get(mediaUrl)
        .then((response) => {
          if (response.data && response.data.source_url) {
            setMedia(response.data.source_url);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [content]);

  if (!content) return null;

  const pathname = `/contents/${content.id}`;
  return (
    <Row className="band mb-2">
      <Col className="ps-0 pe-0">
        {loading ? (
          <div className="d-flex justify-content-center mt-5 mb-5">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : media ? (
          <Image
            src={media}
            thumbnail
            width={250}
            height={250}
            className="mx-auto d-block mb-3"
          />
        ) : null}
        <article>
          <NavLink to={{ pathname }}>
            <h1 dangerouslySetInnerHTML={{ __html: content.title.rendered }} />
          </NavLink>
          <p
            className="card-text mt-3 font-smaller"
            dangerouslySetInnerHTML={{ __html: content.content.rendered }}
          />
          <span>{moment(content.date).format("MMMM D YYYY")}</span>
        </article>
      </Col>
    </Row>
  );
}

export default Content;
