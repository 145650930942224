import React from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

function Sidebar() {
    return (
        <>
            <div id="sidebar" className="pt-4 pb-4 ps-3 pe-3 mb-3 bg-light shadow-sm">
                <Nav defaultActiveKey="/" className="flex-column">
                    <Nav.Link as={NavLink} end to="/opportunities" className="main-link">Opportunities</Nav.Link>
                    <Nav.Link as={NavLink} end to="/opportunities/employment">Employment</Nav.Link>
                    <Nav.Link as={NavLink} end to="/opportunities/volunteer">Volunteer</Nav.Link>
                    {/* <Nav.Link as={NavLink} end to="/about/contact">Contact Us</Nav.Link> */}
                </Nav>
            </div>
        </>
    );
}

export default Sidebar;
