import React, { useState } from "react";
import { Container, Col, Row, Alert } from "react-bootstrap";
import Breadcrumbs from "./Breadcrumbs";
import Sidebar from "./Sidebar";
import ScrollToTopOnMount from "../ScrollToTopOnMount";
import GoToTop from "../GoToTop";
import Email from "../shared/Email";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import axios from "axios";

function PublicRecordsRequest() {
  const [inputName, setInputName] = useState("");
  const [inputEmail, setInputEmail] = useState("");
  const [inputPhone, setInputPhone] = useState("");
  const [inputDate, setInputDate] = useState("");
  const [inputAddress, setInputAddress] = useState("");
  const [inputMessage, setInputMessage] = useState("");
  const [isLoading, setIsLoading] = useState("");
  const [isSuccess, setIsSuccess] = useState(undefined);

  const submitHandler = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("your-name", inputName);
    formData.append("phone", inputPhone);
    formData.append("email", inputEmail);
    formData.append("date-incident", inputDate);
    formData.append("address", inputAddress);
    formData.append("message", inputMessage);
    try {
      setIsLoading(true);
      await axios({
        method: "post",
        url: "https://cmfe.org/blog/wp-json/contact-form-7/v1/contact-forms/2744/feedback",
        data: formData,
        headers: {
          "Content-Type": "multipart-form-data",
        },
      })
        .then((response) => {
          setIsLoading(false);
          console.log(response);
          if (response.status === 200) {
            setIsSuccess(true);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setIsSuccess(false);
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }

    return false;
  };

  return (
    <>
      <Container id="servicesforms" className="main">
        <ScrollToTopOnMount />
        <Container className="page">
          <Breadcrumbs activePage="Public Records Requests" />
          <Container>
            <Row>
              <Col md={9}>
                <h1>Public Records Requests</h1>
                {/* <section className="mt-3 mb-5">
                  <p>
                    Need to make a Public Records Request?{" "}
                    <a
                      href={
                        process.env.PUBLIC_URL +
                        `/files/forms/22_CM_F1002_Records_Request_Form.pdf`
                      }
                      alt={`Central Mason Fire-EMS Public Records Request form pdf file`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Click here
                    </a>{" "}
                    for an application. You can turn it into our headquarters
                    station.
                  </p>

                  <Alert className="text-center p-2 m-3" variant="warning">
                    We are not accepting electronic signatures. Each records
                    request must be physically signed. At this time we are
                    accepting a photograph copy of a signed records request, we
                    ask that the entire document is readable within the photo.
                    You can email the completed request to{" "}
                    <Email email="info@cmfe.org" />.
                  </Alert>
                </section> */}
                <section className="mt-3 mb-5">
                  <p>
                    Central Mason Fire & EMS is committed to providing full
                    access to public records in accordance with the Washington
                    State Public Records Act governed by RCW 42.56.
                  </p>
                  <br />
                  <p>
                    For medical information, Central Mason complies with the
                    Health Insurance Portability and Accountability Act (HIPAA)
                    and RCW 70.02, Medical Records- Health Care Information
                    Access and Disclosure.{" "}
                  </p>
                  <p>
                    <u>Patients</u>: To request a copy of your report, you must
                    provide photo identification in person.
                  </p>
                  <p>
                    <u>Attorneys and Other Agencies</u>: To request a report on
                    behalf of a client, submit a written request on letterhead
                    along with authorization to release medical information
                    signed by your client.
                  </p>
                  <p>
                    <u>All Other Public Records</u>: For all other records
                    please fill out the form below or print the{" "}
                    <a
                      href={
                        process.env.PUBLIC_URL +
                        `/files/CM_F1002_Records_Request_Form.pdf`
                      }
                      alt={`Records Request
                    Form pdf file`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Records Request Form
                    </a>{" "}
                    and submitting it to our office via one of the options
                    below:{" "}
                  </p>
                </section>
                <Row>
                  <Col md={4}>
                    <div className="pt-5">
                      <Link
                        className="btn btn-sm btn-danger mx-auto d-block"
                        to="/public-records-request/exemptions"
                      >
                        Exemptions
                      </Link>
                      <Link
                        className="btn btn-sm btn-danger mx-auto d-block mt-3"
                        to="/public-records-request/fees"
                      >
                        Fees
                      </Link>
                      <a
                        className="btn btn-sm btn btn-danger mx-auto d-block mt-3"
                        href={
                          process.env.PUBLIC_URL +
                          `/files/3300G_Requests_for_Public_Records.pdf`
                        }
                        alt={`CMFE Request for Public Records Guideline pdf file`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        CMFE Request for Public Records Guideline
                      </a>
                    </div>
                  </Col>
                  <Col md={8}>
                    <section className="pt-5">
                      {isSuccess ? (
                        <Alert variant='info' className="mb-3">
                            <strong>Thanks for contacting us! We will be in touch with you
                            shortly.</strong>
                        </Alert>
                      ) : null}
                      {!isLoading ? (
                        <>
                          Fields marked with an{" "}
                          <span style={{ color: "red" }}>*</span> are required
                          <Form onSubmit={submitHandler} action="POST">
                            <Form.Group className="mb-3" controlId="your-name">
                              <Form.Label>Name{" "}
                          <span style={{ color: "red" }}>*</span></Form.Label>
                              <Form.Control
                                required
                                type="text"
                                value={inputName}
                                onChange={(e) => setInputName(e.target.value)}
                                placeholder=""
                              />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="phone">
                              <Form.Label>Phone{" "}
                          <span style={{ color: "red" }}>*</span></Form.Label>
                              <Form.Control
                                required
                                type="number"
                                value={inputPhone}
                                onChange={(e) => setInputPhone(e.target.value)}
                                placeholder=""
                              />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="email">
                              <Form.Label>Email{" "}
                          <span style={{ color: "red" }}>*</span></Form.Label>
                              <Form.Control
                                required
                                type="email"
                                value={inputEmail}
                                onChange={(e) => setInputEmail(e.target.value)}
                                placeholder=""
                              />
                            </Form.Group>
                            <Form.Group
                              className="mb-3"
                              controlId="date-incident"
                            >
                              <Form.Label>Date Incident Occurred{" "}
                          <span style={{ color: "red" }}>*</span></Form.Label>
                              <Form.Control
                                required
                                type="date"
                                value={inputDate}
                                onChange={(e) => setInputDate(e.target.value)}
                                placeholder=""
                              />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="address">
                              <Form.Label>Address of Incident{" "}
                          <span style={{ color: "red" }}>*</span></Form.Label>
                              <Form.Control
                                required
                                type="text"
                                value={inputAddress}
                                onChange={(e) =>
                                  setInputAddress(e.target.value)
                                }
                                placeholder=""
                              />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="message">
                              <Form.Label>Message</Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={5}
                                value={inputMessage}
                                onChange={(e) =>
                                  setInputMessage(e.target.value)
                                }
                                placeholder="Please indicate what information you are requesting"
                              />
                            </Form.Group>
                            <Button variant="primary" type="submit">
                              Submit
                            </Button>
                          </Form>
                        </>
                      ) : null}
                      {isLoading ? (
                        <Container>
                          <div className="d-flex justify-content-center mt-5 mb-3">
                            <div className="spinner-border" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        </Container>
                      ) : null}
                    </section>
                  </Col>
                </Row>
                <section className="pt-5">
                  <div className="text-center">
                    <p>
                      <strong>Email:</strong>
                      <p>
                        <Email email="info@cmfe.org" />
                      </p>
                    </p>
                    <p>
                      <strong>Fax:</strong>
                      <p>360-427-9438</p>
                    </p>
                    <p>
                      <strong>In Person</strong>
                      <p>
                        Central Mason Fire & EMS Station 58
                        <br />
                        122 W. Franklin St. Shelton, WA 98584
                      </p>
                    </p>
                  </div>
                </section>
              </Col>
              <Col md={3}>
                <Sidebar />
              </Col>
            </Row>
          </Container>
        </Container>
        <GoToTop />
      </Container>
    </>
  );
}

export default PublicRecordsRequest;
