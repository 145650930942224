import React, { useRef, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import GoToTop from '../GoToTop';
import Breadcrumbs from './Breadcrumbs';

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

function Mission() {
    const visionRef = useRef(null);
    const valuesRef = useRef(null);

    const hash = useLocation().hash;

    useEffect(() => {
        if (hash && hash.indexOf('vision') > -1 && visionRef && visionRef.current) {
            scrollToRef(visionRef);
        }
        if (hash && hash.indexOf('values') > -1 && valuesRef && valuesRef.current) {
            scrollToRef(valuesRef);
        }
    }, [hash, visionRef, valuesRef]);

    return (
        <>
            <Container id="mission" className="main">
                <ScrollToTopOnMount />
                <Container className="page">
                    <Breadcrumbs activePage="Our Mission, Vision, and Values" />
                    <Container>
                        <Row>
                            <Col className="left" md={{ span: 9, order: 1 }} sm={{ span: 12, order: 2 }}>
                                <h1 className="mb-3">Our Mission</h1>
                                <div className="mb-3">
                                    <p>To make a positive difference in the place where people live, work, and play by providing the highest level of fire and emergency services.</p>
                                </div>
                                <h1 ref={visionRef} className="mb-3">Our Vision</h1>
                                <div className="mb-3">
                                    <p>We will be the leading resource for a safer community.</p>
                                    <p className="pb-0">We will:</p>
                                    <ul>
                                        <li className="item">Be an organization held in high esteem and enthusiastically supported by the community and by our strategic partners.</li>
                                        <li className="item">Be a team who provides efficient and effective response, prevention, public education, and communication services and whose members are effective, empowered and enthusiastic.</li>
                                        <li className="item">Be a proactive leader in emergency services with well-trained personnel, with a program of aggressive firefighting, risk reduction, and public education.</li>
                                        <li className="item">Be a leader in Mason County in emergency medical services innovation and committed to the professional development of its members.</li>
                                        <li className="item">Provide highly responsive advanced life support, basic life support, ambulance transport services.</li>
                                        <li className="item">Be an organization recognized for its caring and high-quality service.</li>
                                        <li className="item">Be a customer and service-oriented organization committed to actionable solutions that we can apply to every emergent and non-emergent situation we face.</li>
                                    </ul>
                                    <p>We will achieve our vision through dynamic leadership, adaptability, open communication, learning collaboration, and shared purpose.</p>
                                </div>
                                <h1 ref={valuesRef} className="mb-3">Our Values</h1>
                                <div className="mb-3">
                                    <ol id="values" className="list">
                                        <li className="item">
                                            <h4 className="headline">DUTY</h4>
                                            <span>We have chosen and sworn to serve our community, our strategic partners, and each other. We will ensure competence, skillfulness, and effectiveness in the operational environment. We are driven to cultivate equitable and responsible solutions to complex and challenging issues.</span>
                                        </li>

                                        <li className="item">
                                            <h4 className="headline">INTEGRITY</h4>
                                            <span>We will do what is right, even when it’s the hard right. We will think and act ethically in all aspects of department business.</span>
                                        </li>

                                        <li className="item">
                                            <h4 className="headline">RESPECT</h4>
                                            <span>We will be nice and kind, all the time. We affirm and elevate equity to all team and community members. We are always considerate of people’s experiences and backgrounds.</span>
                                        </li>

                                        <li className="item">
                                            <h4 className="headline">COMPASSION</h4>
                                            <span>We always operate knowing that we provide essential lifesaving and community-focused services under a myriad of conditions and that these services must be delivered in a professional, thoughtful, considerate, and ethical manner.</span>
                                        </li>

                                        <li className="item">
                                            <h4 className="headline">RELIABILITY</h4>
                                            <span>We will be proactive in identifying the needs of the community as they pertain to emergency and non-emergency services. We will follow through on promises and commitments. We are entrusted and fit to be the caretakers of publicly owned resources.</span>
                                        </li>

                                        <li className="item">
                                            <h4 className="headline">ORGANIZATIONAL MINDFULNESS</h4>
                                            <span>We will rethink fire and emergency service traditions and customs that do not add value to the organization. We establish team member well-being, safety, and health as essential for organizational success.</span>
                                        </li>
                                    </ol>
                                </div>

                                {/* <h1>Department Facts</h1>

                                <Button className="mt-2 mb-4" variant="dark" onClick={handleShowFactsSheet}>
                                    View Department Facts Sheet
                                </Button> */}
                                {/* <embed src={process.env.PUBLIC_URL + `/files/20_0106_CMFE-Facts-Sheet.pdf`} frameBorder="0" width="100%" height="680px" /> */}

                                {/* <h1>Organizational Chart</h1>
                                <Button className="mt-2 mb-2" variant="dark" onClick={handleShow}>
                                    View Organizational Chart
                                </Button>
                                <embed src={process.env.PUBLIC_URL + `/files/CMFE-Org-Chart-08-23-22.pdf`} frameBorder="0" width="100%" height="680px" /> */}
                            </Col>
                            <Col className="right" md={{ span: 3, order: 2 }} sm={{ span: 12, order: 1 }}>
                                <Sidebar />
                            </Col>
                        </Row>

                    </Container>
                </Container>
                <GoToTop />
            </Container>
            {/* <Modal dialogClassName="modal-90w" show={showFactsSheet} onHide={handleCloseFactsSheet}>
                <Modal.Header closeButton>
                    <Modal.Title>Central Mason Fire-EMS Facts Sheet</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <embed src={process.env.PUBLIC_URL + `/files/20_0106_CMFE-Facts-Sheet.pdf`} frameBorder="0" width="100%" height="800px" />
                </Modal.Body>
            </Modal>
            <Modal dialogClassName="modal-90w" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Central Mason Fire-EMS Organization Chart</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <embed src={process.env.PUBLIC_URL + `/files/CMFE-Org-Chart-6-10-20.pdf`} frameBorder="0" width="100%" height="800px" />
                </Modal.Body>
            </Modal> */}
        </>
    );
}

export default Mission;
