import React, { useState } from 'react';
import { Container, Col, Row, Button, Modal } from 'react-bootstrap';
import Breadcrumbs from './Breadcrumbs';
import Sidebar from './Sidebar';
// import Phone from '../shared/Phone';
import ExternalLink from '../shared/ExternalLink';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import GoToTop from '../GoToTop';

function ProtectYourHome() {
    const [filePath, setFilepath] = useState('');
    const [modalTitle, setModalTitle] = useState('');

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <Container id="fire-adapted-communities" className="main">
                <ScrollToTopOnMount />
                <Container className="page">
                    <Breadcrumbs activePage="Protect Your Home" />
                    <Container>
                        <Row>
                            <Col md={9}>
                                <h1>Protect Your Home</h1>
                                <section className="mt-3 mb-3">
                                     {/* <embed src={process.env.PUBLIC_URL + `/files/CPR-2020-Flyer-1-768x994.jpg`} frameBorder="0" width="100%" /> */}
                                     {/* <Button className="mt-2 mb-2" variant="dark" onClick={() => {
                                        setFilepath(`${process.env.PUBLIC_URL}/files/CPR-2020-Flyer-.pdf`);
                                        setModalTitle('CPR First Aid Classes');
                                        handleShow();
                                    }}> */}
                                     {/* <embed src={process.env.PUBLIC_URL + `/files/CPR-2020-Flyer-1-768x994.jpg`} frameBorder="0" width="100%" /> */}
                                     {/* <Button className="mt-2 mb-2" variant="dark" onClick={() => {
                                        setFilepath(`${process.env.PUBLIC_URL}/files/CPR-2020-Flyer-.pdf`);
                                        setModalTitle('CPR First Aid Classes');
                                        handleShow();
                                    }}> */}
                                        {/* View PDF (Spanish)
                                    </Button> */}
                                </section>

                                <section className="mt-3 mb-3">
                                    {/* <h4 className="headline">Creating Defensible Space Around Your Home</h4> */}
                                    {/* <iframe title="-" height="315" src="https://www.youtube-nocookie.com/embed/6YidrbZZe0A" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" width="100%" allowfullscreen></iframe> */}

                                    <iframe title="-" height="315" src="https://www.youtube-nocookie.com/embed/nyb6PIwTvAk" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" width="100%" allowfullscreen></iframe>
                                    
                                    <iframe title="-" height="315" src="https://www.youtube-nocookie.com/embed/pfbEcMeYFFA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" width="100%" allowfullscreen></iframe>
                                    
                                </section>

                                
                                <section className="mt-3 mb-3">
                                    <h4 className="headline">Creating Defensible Space Around Your Home</h4>
                                    <section className="text-center">
                                    <embed className="shadow-sm" src={process.env.PUBLIC_URL + `/files/DefensibleSpaceZones.png`} frameBorder="0" width="100%" /><br />
                                    <embed className="shadow-sm" src={process.env.PUBLIC_URL + `/files/MinimumVerticalClearance.png`} frameBorder="0" width="100%" /><br />
                                    <embed className="shadow-sm" src={process.env.PUBLIC_URL + `/files/MinimumHorizontalClearance.png`} frameBorder="0" height="680px" /><br />
                                    <Button className="mt-2 mb-2" variant="dark" onClick={() => {
                                        setFilepath(`${process.env.PUBLIC_URL}/files/MinimumHorizontalClearance.pdf`);
                                        setModalTitle('Minimum Horizontal Clearance');
                                        handleShow();
                                    }}>
                                        View Minimum Horizontal Clearance PDF
                                    </Button>
                                    </section>
                                    
                                </section>

                                <section className="mt-3 mb-3">
                                    <h4 className="headline">Useful Links</h4>
                                    
                                    <ExternalLink label="Room-by-Room Guide to a Safer Home" href="https://www.safewise.com/resources/home-safety/" /><br />
                                    <ExternalLink label="Close Before You Doze: Fire Safety While You Sleep" href="https://closeyourdoor.org/" /><br />
                                    <ExternalLink label="20 Hidden Things in Your Home That May Be a Fire Hazard" href="https://www.familyhandyman.com/smart-homeowner/these-20-hidden-things-in-your-home-may-be-a-fire-hazard/" /><br />
                                    <ExternalLink label="Checklist: Do Your Home Materials Pose a Fire Risk?" href="https://www.angieslist.com/articles/do-your-home-materials-pose-fire-risk.htm" /><br />
                                    <ExternalLink label="10 Pet Fire Safety Tips to Start Practicing Now" href="https://figopetinsurance.com/blog/10-pet-fire-safety-tips-start-practicing-now" />
                                </section>
                                
                            </Col>
                            <Col md={3}>
                                <Sidebar />

                            </Col>
                        </Row>
                    </Container>
                </Container>
                <GoToTop />
            </Container>
            <Modal dialogClassName="modal-90w" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <embed src={filePath} frameBorder="0" width="100%" height="800px" />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ProtectYourHome;
