import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AppContext } from "../../AppContext";
import Breadcrumbs from "../community/Breadcrumbs";
import Sidebar from "../community/Sidebar";
import ScrollToTopOnMount from "../ScrollToTopOnMount";
import GoToTop from "../GoToTop";

function BurningRestrictions() {
  const { pages, pagesAreLoading } = useContext(AppContext);
  const [burningRestrictions, setBurningRestrictions] = useState({});

  useEffect(() => {
    const getBurningRestrictions = () => {
      if (pages) {
        let burningRestrictionsPage = {};
        pages.forEach((page, index) => {
          if (page && page.link.indexOf("burning-restrictions") > -1) {
            burningRestrictionsPage = page;
          }
        });
        setBurningRestrictions(burningRestrictionsPage);
      }
    };

    if (pages) {
      getBurningRestrictions();
    }
  }, [pages]);

  if (pagesAreLoading) {
    return (
      <Container className="main">
        <ScrollToTopOnMount />
        <Container className="page">
          <Breadcrumbs activePage="Burn Restrictions" />
          <Container>
            <Row>
              <Col md={9}>
                <Container>
                  <div className="d-flex justify-content-center mt-5 mb-3">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </Container>
              </Col>
              <Col md={3}>
                <Sidebar />
              </Col>
            </Row>
          </Container>
        </Container>
        <GoToTop />
      </Container>
    );
  }

  if (!pages) return null;

  return (
    <Container className="main">
      <ScrollToTopOnMount />
      <Container className="page">
        <Breadcrumbs activePage="Burn Restrictions" />
        <Container>
          <Row>
            <Col md={9}>
              {burningRestrictions &&
              burningRestrictions.content &&
              burningRestrictions.content.rendered ? (
                <div
                  className="post-content mt-3"
                  dangerouslySetInnerHTML={{
                    __html: burningRestrictions.content.rendered,
                  }}
                />
              ) : null}
            </Col>
            <Col md={3}>
              <Sidebar />
            </Col>
          </Row>
        </Container>
      </Container>
      <GoToTop />
    </Container>
  );
}

export default BurningRestrictions;
