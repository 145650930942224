import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AppContext } from "../../AppContext";
import Breadcrumbs from "./Breadcrumbs";
import Sidebar from "./Sidebar";
import GoToTop from "../GoToTop";
import ScrollToTopOnMount from "../ScrollToTopOnMount";

function Gallery() {
  const { pages, pagesAreLoading } = useContext(AppContext);
  const [gallery, setGallery] = useState({});

  useEffect(() => {
    const getDocumentsPage = () => {
      if (pages) {
        let documentsPage = {};
        pages.forEach((page, index) => {
          if (page && page.link.indexOf("blog/gallery") > -1) {
            documentsPage = page;
          }
        });
        setGallery(documentsPage);
      }
    };

    if (pages) {
      getDocumentsPage();
    }
  }, [pages]);

  if (pagesAreLoading) {
    return (
      <>
        <Container id="gallery" className="main">
          <ScrollToTopOnMount />
          <Container className="page">
            <Breadcrumbs activePage="Gallery & Reports" />
            <Row>
              <Col md={9}>
                <Container>
                  <h1>Gallery</h1>
                  <Container className="main align-content-start">
                    <Container>
                      <div className="d-flex justify-content-center mt-5 mb-3">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    </Container>
                  </Container>
                </Container>
              </Col>
              <Col md={3}>
                <Sidebar />
              </Col>
            </Row>
          </Container>
          <GoToTop />
        </Container>
      </>
    );
  }

  if (!pages) return null;

  return (
    <>
      <Container id="gallery" className="main">
        <ScrollToTopOnMount />
        <Container className="page">
          <Breadcrumbs activePage="Gallery" />
          <Row>
            <Col md={9}>
              <Container>
                <h1>Gallery</h1>
                {gallery &&
                gallery.content &&
                gallery.content.rendered ? (
                  <div
                    className="mt-3"
                    dangerouslySetInnerHTML={{
                      __html: gallery.content.rendered,
                    }}
                  />
                ) : null}
              </Container>
            </Col>
            <Col md={3}>
              <Sidebar />
            </Col>
          </Row>
        </Container>
        <GoToTop />
      </Container>
    </>
  );
}

export default Gallery;
