import React from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import News from "../News3";
import FrontPage from "../FrontPage";
// import WelcomeMessage from "./WelcomeMessage";
import WelcomeMessage from "./WelcomeMessageDynamic";
import MissionMotto from "./MissionMotto";
import { NavLink } from "react-router-dom";
import UpcomingEvents from "../events/UpcomingEvents";
// import Latest from './Latest';
import LatestDynamic from "./LatestDynamic";
// import Fireworks from './Fireworks';
// import NewsRelease from './NewsRelease';
import BurnBanInfo from "../safety/BurnBanInfo";
// import FireLevy from './FireLevy';
// import PostLevy from './PostLevy';
// import PressReleaseLatest from './PressReleaseLatest';
// import NoticeToPublic from './NoticeToPublic';
import Contents from "../Contents";
import PressReleases from "../home/PressReleases";

// import Posts from '../pages/Posts';

function Welcome() {
  return (
    <Container fluid="lg" id="welcome-container">
      <Container>
        <Row>
          <Col sm={8} className="pe-0">
            {/* <Container fluid className="ps-3 pt-3 pb-3"> */}
            <div className="jumbotron p-5 mb-4 bg-light rounded-3">
                <div className="container-fluid py-5 g-0">
              <WelcomeMessage />
                </div>
                </div>
            <Row id="our-mission">
              <MissionMotto />
            </Row>
            {/* <Row id="latest-message">
                            <NoticeToPublic />
                        </Row> */}
            <Row>
              <Contents />
            </Row>
            {/* <Row id="post-levy">
                            <div>
                                <PostLevy />
                            </div>
                        </Row> */}
            {/* <Row id="news-release">
                            <div>
                                <NewsRelease />
                            </div>
                        </Row> */}
            {/* <Row id="fireworks">
                            <div>
                                <Fireworks />
                            </div>
                        </Row> */}
            {/* <Row id="fire-levy">
                            <div>
                                <FireLevy />
                            </div>
                        </Row> */}
            <Row>
              <FrontPage />
            </Row>
            <Row>
              <News />
            </Row>
            <Row id="press-release-latest">
              <div>
                {/* <PressReleaseLatest /> */}
                <PressReleases />
              </div>
            </Row>
            {/* <Row>
                            <Contents />
                        </Row> */}
          </Col>
          <Col id="welcome-sidebar" sm={4} className="ps-0 pe-0">
            <Container fluid className="pt-3 pb-3 m-0">
              <section className="border-shadow text-center">
                <h5>EVENTS</h5>
                <div className="p-0">
                  <UpcomingEvents />
                </div>
                {/* <hr className="ms-3 me-3" />
                <div className="small" style={{ marginTop: "2rem" }}>
                  <h6 className="text-center mb-3">
                    REGULAR MEETING ZOOM INFO
                  </h6>
                  <strong>Meeting ID:</strong> 850 3466 5686
                  <br />
                  <strong>Meeting Passcode:</strong> 123789
                  <br />
                  <strong>Meeting link:</strong>{" "}
                  <a
                    href="https://us02web.zoom.us/s/85034665686?pwd=bGdrN1VIZGE0UWxsRVZiTHRpaEFhQT09"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://us02web.zoom.us/s/85034665686?pwd=bGdrN1VIZGE0UWxsRVZiTHRpaEFhQT09
                  </a>
                  <br />
                  <br />
                  <p>
                    NOTE: For Special Meetings (labeled as such), the Zoom
                    meeting information might be different, so please verify the
                    information that you are using.
                  </p>
                </div> */}
              </section>
              {/* <Latest /> */}
              <LatestDynamic />
              <section className="border-shadow text-center">
                <h5>BURN RESTRICTIONS</h5>
                <div>
                  <BurnBanInfo />
                  <div className="text-center p-0 pt-2">
                    <Button
                      variant="dark"
                      as={NavLink}
                      to="/community/burnrestrictions"
                      size="sm"
                    >
                      Learn more...
                    </Button>
                  </div>
                </div>
              </section>
              <section id="facebook" className="border-shadow text-center">
                <h5>CMFE Facebook</h5>
                <div
                  className="fb-page"
                  data-href="https://www.facebook.com/CMFE5"
                  data-tabs="timeline"
                  data-width=""
                  data-height=""
                  data-small-header="false"
                  data-adapt-container-width="true"
                  data-hide-cover="false"
                  data-show-facepile="true"
                >
                  <blockquote
                    cite="https://www.facebook.com/CMFE5"
                    className="fb-xfbml-parse-ignore"
                  >
                    <a href="https://www.facebook.com/CMFE5">
                      CMFE on Facebook
                    </a>
                  </blockquote>
                </div>
                <div
                  className="fb-like mt-3"
                  data-href="https://www.facebook.com/CMFE5"
                  data-width=""
                  data-layout="button_count"
                  data-action="like"
                  data-size="small"
                  data-share="true"
                ></div>
              </section>
              <section
                id="station-locations"
                className="border-shadow text-center"
              >
                <h5>STATION LOCATIONS</h5>
                <div className="text-center">
                  <iframe
                    title="Our stations on Google Maps"
                    src="https://www.google.com/maps/d/u/0/embed?mid=10s4igIkSVJYfLQ_4YJhMJ-ZM9sioAniN"
                    style={{ width: "100%", height: "600px", border: "1px" }}
                    className="border-shadow"
                  />
                </div>
              </section>
            </Container>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Welcome;
