import React, { Component } from "react";
import {
    Calendar,
    momentLocalizer,
} from 'react-big-calendar';
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import EventAgenda from './EventAgenda';
import Event from './Event';
import eventUtil from '../../utils/event';

const localizer = momentLocalizer(moment);
    
class MyCalendar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentEvent: null,
            calendar: [],
            calendarUrl: 'https://www.cmfe.org/blog/wp-json/tribe/events/v1/events?start_date=2021-01-01&per_page=100',
            events: [],
            loading: true
        }

    }

    componentDidMount() {
        fetch(this.state.calendarUrl)
        .then(res => res.json())
        .then(cal => this.setState((prevState, props) => {
            return {
                loading: false,
                calendar: cal.events.map(eventUtil.mapEvent) 
            };
        }));
    }

    renderCalendar() {
        const formats = {
            eventTimeRangeFormat: () => ''
        };
        
        return (
            <div>
                <Calendar
                    popup
                    events={this.state.calendar}
                    localizer={localizer}
                    defaultDate={new Date()}
                    defaultView="month"
                    // style={{ height: "800px" }}
                    startAccessor="start"
                    endAccessor="end"
                    titleAccessor="test"
                    formats={formats}
                    components={{
                        event: Event,
                        agenda: {
                            event: EventAgenda
                        },
                        week: {
                            event: Event
                        }
                    }}
                    scrollToTime={new Date(new Date().setHours(7, 0))}
                />
            </div>
        );
    }

    renderSpinner() {
        return (
            <div className="d-flex justify-content-center mb-3">
                <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    render() {
        return this.state.loading ? this.renderSpinner() : this.renderCalendar();
    }
}

export default MyCalendar; 