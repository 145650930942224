import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import ScrollToTopOnMount from "../ScrollToTopOnMount";
import GoToTop from "../GoToTop";
import Email from "../shared/Email";

function ChiefsMessage() {
  return (
    <>
      <Container id="chiefs-message" className="main">
        <ScrollToTopOnMount />
        <Container className="page">
          <Breadcrumb>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/about" }}>
              About
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Fire Chief's Message</Breadcrumb.Item>
          </Breadcrumb>
          <Container>
            <Row>
              <Col md={9}>
                <h1>Fire Chief's Message</h1>
                <article>
                  {/* <Image
                                    className="w-25 p-1 me-4 mb-3"
                                    fluid
                                    rounded
                                    src={process.env.PUBLIC_URL + `/images/ChiefPatti.jpg`}
                                    alt="Fire Chief Mike Patti"
                                    style={{ float: 'right' }}
                                />
                                <p>
                                    As the Fire Chief, I am privileged to lead a fire service organization comprised of a committed staff whose goal is to provide the highest level of service available while maintaining fiscal responsibility. Over the past several years, the fire department has secured several million dollars in federal  grants to purchase state of the art equipment. This equipment allows the men and women of Central Mason Fire and EMS to fulfill their commitment to protecting the lives and properties of the citizens and visitors of Central Mason and the City of Shelton.
                                </p>
                                <p>
                                    Above all else, we honor the duty to our profession, respect and compassion for all, and a commitment to excellence.
                                </p>
                                <p>
                                    Our staff is encouraged to advance their education throughout their careers and have achieved advanced degrees in numerous fields of study. Each firefighter holds either an Emergency Medical Technician or Paramedic certification from the State of Washington and an Advanced Firefighter designation from the Office of the State Fire Marshal. Staff have also obtained other endorsements such as Fire service Instructor, Fire Investigator and Fire Code Inspector.
                                </p>
                                <p>
                                    Earlier this year, Central Mason was pleased to announce our Fire Protection Classification Rating reduction to a Protection Class “5”, effective May 1, 2021.
                                </p>
                                <p>
                                    Finally, I would like to thank the citizens of Central Mason, and the Mason County Fire District #5 Board of Fire Commissioners  and the City of Shelton for their continued support.  This close relationship allows us the ability to achieve our goal of providing the highest level of service possible for the citizens and business community of Fire District #5 and the City of Shelton that makes up Central Mason Fire and EMS!
                                </p>
                                <p>
                                    Sincerely,
                                </p>
                                <p>
                                    Michael D. Patti<br />
                                    Fire Chief<br />
                                    <Email email="mpatti@cmfe.org" />
                                </p> */}

                  <p>
                    First, I would like to take a few moments to thank all those
                    who have helped me rise to the rank of Fire Chief with
                    Central Mason Fire & EMS. When I first walked through the
                    fire hall doors in 1987 as a recruit firefighter, I was
                    completely unaware of the journey I was starting. I thought
                    I would be a volunteer firefighter for a couple of years.
                    Maybe add a little excitement and adventure to my life and
                    help my community in the process. I did not have any
                    aspirations of moving on with a career in the fire service.
                    Most certainly I did not envision I would have 30-plus
                    years, working all the ranks of our organization from
                    recruit to Fire Chief. I would need a sheet of paper the
                    length of both my arms and possibly one of my legs to thank
                    all who have helped and guided me. So, to all Thank you.
                  </p>

                  <p>
                    The fire service like most things has sure changed. Our call
                    volume in the mid-1980s was less than 1000 calls a year. Now
                    we are pushing 9000 runs. It would not surprise me to see
                    our organization exceed 10,000 911 calls in a few short
                    years. The demands placed on the fire service grow every
                    year. From the obvious such as call volume to the
                    not-so-obvious like keeping up with new technology,
                    training, new laws, protocols, and societal demands. All
                    this increases the workload on our staff. As the Fire Chief,
                    I see what is asked of our crews. I witness how our
                    personnel handle the challenges that are thrown at them. I’m
                    constantly reminded of the dedication, and professionalism,
                    our firefighters, and paramedic bring to our community
                    daily. I could not be prouder of them.
                  </p>

                  <p>
                    Last but certainly not least, the success of Central Mason
                    Fire & EMS would not be possible without the support of our
                    citizens, our community, and the people we serve. Just
                    recently our citizenry voted to increase their taxes on a
                    fire levy. It is not beyond us here at CMFE that we are in
                    difficult times in our community. It is getting harder and
                    harder to stretch the proverbial dollar either here at
                    Central Mason or at our taxpayer’s home. Yet in these
                    difficult times, the voters supported us. Thank you for your
                    support. It is my pledge to you as the Fire Chief, we at
                    CMFE will work to make those dollars go further.
                  </p>

                  <p>Thank you for your time.</p>
                  <p>
                    Jeff Snyder
                    <br />
                    Fire Chief
                    <br />
                    <Email email="JSnyder@cmfe.org" />
                  </p>
                </article>
              </Col>
              <Col md={3}>
                <Sidebar />
              </Col>
            </Row>
          </Container>
        </Container>
        <GoToTop />
      </Container>
    </>
  );
}

export default ChiefsMessage;
