import React, { useEffect, useRef } from 'react';
import { Container, Col, Row, Alert, Image } from 'react-bootstrap';
import Breadcrumbs from './Breadcrumbs';
import Sidebar from './Sidebar';
import ExternalLink from '../shared/ExternalLink';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import GoToTop from '../GoToTop';
import Email from '../shared/Email';
import Phone from '../shared/Phone';
import { useParams } from 'react-router-dom';

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

function ServicesForms() {
    // const hash = useLocation().hash;
    const { type } = useParams();
    const publicRecordsRef = useRef(null);
    const stationToursRef = useRef(null);
    const ambulanceBillingRef = useRef(null);
    const speakersRef = useRef(null);

    useEffect(() => {
        if (type && type.indexOf('public-records-request') > -1 && publicRecordsRef && publicRecordsRef.current) {
            scrollToRef(publicRecordsRef);
        }
        if (type && type.indexOf('station-tours') > -1 && stationToursRef && stationToursRef.current) {
            scrollToRef(stationToursRef);
        }
        if (type && type.indexOf('ambulance-billing') > -1 && ambulanceBillingRef && ambulanceBillingRef.current) {
            scrollToRef(ambulanceBillingRef);
        }
        if (type && type.indexOf('speakers') > -1 && speakersRef && speakersRef.current) {
            scrollToRef(speakersRef);
        }
    }, [type, publicRecordsRef, stationToursRef, ambulanceBillingRef, speakersRef]);
    return (
        <>
            <Container id="servicesforms" className="main">
                <ScrollToTopOnMount />
                <Container className="page">
                    <Breadcrumbs activePage="Services & Forms" />
                    <Container>
                        <Row>
                            <Col md={9}>
                                <h1>Services & Forms</h1>
                                <section className="mt-3 mb-5">
                                    <h4 className="text-uppercase mb-3">Station Tours</h4>
                                    <p>Did you know CMFE offers station tours at our headquarters station in downtown Shelton? Come meet our firefighters, check out our fire trucks, and meet Kindle the fire dog. To schedule a tour call, <Phone number="360-426-5533" />.</p>
                                </section>
                                <section className="mt-3 mb-5">
                                    <h4 className="text-uppercase mb-3" ref={speakersRef}>Speakers</h4>
                                    <p>Would you like a speaker from the fire department at your next meeting? We can talk about subjects such as wildfire safety, winter fire safety, home fire safety, etc. Call <Phone number="360-426-5533" /> to schedule a speaker.</p>
                                </section>
                                <section className="mt-3 mb-5">
                                    <h4 className="text-uppercase mb-3" ref={publicRecordsRef}>Public Records Requests</h4>
                                    <p>Need to make a  Public Records Request? <a href={process.env.PUBLIC_URL + `/files/forms/22_Records-Request-Form.pdf`} alt={`Central Mason Fire-EMS Public Records Request form pdf file`} target="_blank" rel="noopener noreferrer">Click here</a> for an application. You can turn it into our headquarters station.</p>
                                    
                                    <Alert className="text-center p-2 m-3" variant="warning">We are not accepting electronic signatures. Each records request must be physically signed. At this time we are accepting a photograph copy of a signed records request, we ask that the entire document is readable within the photo. You can email the completed request to <Email email="info@cmfe.org" />.</Alert>
                                </section>
                                <section className="mt-3 mb-5">
                                    <h4 className="text-uppercase mb-3" ref={ambulanceBillingRef}>Ambulance Billing</h4>
                                    <p>Have ambulance billing questions or concerns? Contact our billing company, Systems Design West at 800-585-5242 or visit them on the web at <ExternalLink href="www.systemsdesignwestems.com" /></p>
                                    <Image
                                        src={process.env.PUBLIC_URL + `/images/knox-box.gif`}
                                        rounded
                                        width={200}
                                        className="mx-auto d-block mt-3 mb-3 me-2"
                                    />
                                    {/* <p>Businesses and homeowners can purchase a Knox Box directly from <ExternalLink href="www.knoxbox.com" />.</p>
                                    <p><ExternalLink href="https://youtu.be/0rNQFLfVTYQ" label="Click here"/> for more information. If you have any questions about Knox Box ordering please contact: Deputy Fire Marshal Keith Reitz at <Phone number="360-462-5020" /> or email him at <Email email="kreitz@cmfe.org" />. </p> */}
                                </section>
                            </Col>
                            <Col md={3}>
                                <Sidebar />

                            </Col>
                        </Row>
                    </Container>
                </Container>
                <GoToTop />
            </Container>
        </>
    );
}

export default ServicesForms;
